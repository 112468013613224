@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@100;200;300;400;500;600;700;800;900&display=swap");

dl, ol, ul {
  padding-left: 0;
}

.conference-section {
  margin-top: 5rem;
}
#conference-footer {
  margin: 75px 0;
}

body {
  font-family: "Montserrat", sans-serif !important;
  font-size: 18px !important;
  font-weight: 400 !important;
  margin: 0 !important;
  letter-spacing: initial !important;
  overflow-x: hidden !important;
}
html, body {
  overflow-x: hidden !important;
}
.container {
  max-width: 1300px;
  margin: 0 auto;
  /* width: 100%; */
}
.bopup-container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 9999;
  display: flex;
  justify-content: center;
  align-items: center;
}

.bopup {
  position: relative;
  background-color: #313F50; /* Синий цвет фона */
  color: #fff; /* Белый текст */
  padding: 20px;
  border-radius: 5px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.5); /* Тень */
  text-align: center;
  max-width: 60%; /* Максимальная ширина контейнера */
  /*width: 40%; !* Ширина контейнера на мобильных устройствах *!*/
}

.bopup h2 {
  margin-top: 0;
}

.bopup p {
  font-family: Montserrat, sans-serif;
  /*margin-bottom: 10px;*/
}

.bopup button {
  background-color: #c50101; /* Бежевый цвет кнопки */
  color: white; /* Синий текст кнопки */
  border: none;
  padding: 10px 5px;
  /*border-radius: 1px;*/
  cursor: pointer;
  margin-right: 10px; /* Расстояние между кнопками */

  width: 40%; /* Ширина кнопок на мобильных устройствах */
}

.close-btn {
  position: absolute;
  top: 10px;
  right: 10px;
  cursor: pointer;
  color: white;
}

.close-btn:hover {
  color: red; /* Измените цвет при наведении, если нужно */
}
/*.bopup button:hover {*/
/*  background-color: #FFA07A; !* Измененный цвет кнопки при наведении *!*/
/*}*/
.email-address {
  color: blue;  /* Задаем синий цвет текста */
  font-style: italic;  /* Задаем курсивное начертание текста */
}
.custom-iframe {
  border: 2px solid #ccc;
  border-radius: 5px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  margin: 10px;
}
.header {
  position: fixed;
  z-index: 3;
  width: 100%;
  background-color: rgba(0, 0, 0, 0);
  transition: padding-top 0.4s ease, background-color 0.4s ease;
}

.header-conferences {
  position: fixed;
  z-index: 3;
  width: 100%;
  background: #313F50;
  padding-top: 15px;
  padding-bottom: 10px;
  transition: padding-top 0.4s ease, background-color 0.4s ease;
}

.mobile-conferences {
  background: #313F50;
}

.header.scrolled {
  background: #313F50;
  /* padding-top: 15px; */
  padding-bottom: 15px;
  z-index: 12;
}

.desktop-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: 1300px !important;
  margin: 0 auto;
  transition: padding-top 0.7s ease, margin-top 0.7s ease;
  height: auto;
}

.btel-speakers {
  color:  rgb(49, 63, 81); /* Пример цвета для текста */
  background-color: #E6E7E8; /* Пример фонового цвета */
}

.btel-title {
  color: rgb(49, 63, 81) !important;
}
.btel-package .sponsor-title-conference {
  color: rgb(49, 63, 81); /* Цвет для заголовка SPONSORSHIP */
}

.btel-line-sponsorship {
  background-color: #D85121 !important; /* Цвет линии под заголовком */
}

.btel-price {
  color: #D85121; /* Цвет цены */
}

.btel-button {
  background-color: #313F51; /* Цвет кнопки */
  color: #FFFFFF; /* Цвет текста кнопки */
}


.btel-line {
  background-color:  #D85121 !important; /* Темно-зеленый цвет для линии */
}

.btel-name {
  color: #313F51; /* Темно-зеленый цвет */
  font-weight: bold; /* Примерное изменение стиля */
}


.btel-marquee-background {
  background-color: #E6E7E8; /* Пример светло-зеленого фона */
}

.btel-price {
  color: #D85121 !important; /* Темно-зеленый цвет для цен */
  filter: blur(10px);
  user-select: none; /* Запрещает выделение текста */
}


.btel-button {
  background-color: #D85121 !important; /* Темно-зеленый цвет для кнопок */
  border: 1px solid darkgreen;
  color: white;
}

.desktop-header-conferences {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-top: 55px;
  max-width: 1300px !important;
  margin: 0 auto;
  transition: padding-top 0.7s ease;
}

.breadcrumbs-add {
  padding-top: 140px;
}
.conference-link {
  color: white;
}


@media screen and (min-width: 1023px) and (max-width: 1111px) {
  .container {
    max-width: 950px;
  }
}

.title-conferences {
  font-size: 64px;
  font-weight: 500;
  line-height: 80px;
  color: #313F50;
  margin-bottom: 1px;
}

.popup-container {
  display: flex;
  justify-content: center;
  gap: 80px;
}

.action-block ul li {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 20%;
  font-size: 21px;
}
.action-block-online ul li {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 20%;
  font-size: 21px;
}

.my-custom-popup-style {
  max-height: 400px;
  overflow-y: scroll;
  background: white;
  padding: 10px 20px;
  text-align: left;
  max-width: 400px;
  margin: 40px auto;
  position: relative;
  border-radius: 0;
  /* Дополнительные стили для попапа */
}

.my-custom-popup-style .zoom-anim-dialog {
  opacity: 0;
  -webkit-transition: all 0.2s ease-in-out;
  -moz-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
  -webkit-transform: scale(0.8);
  -moz-transform: scale(0.8);
  -ms-transform: scale(0.8);
  -o-transform: scale(0.8);
  transform: scale(0.8);
  /* Дополнительные стили для анимации попапа */
}

.my-custom-popup-style.mfp-ready .zoom-anim-dialog {
  opacity: 1;
  -webkit-transform: scale(1);
  -moz-transform: scale(1);
  -ms-transform: scale(1);
  -o-transform: scale(1);
  transform: scale(1);
}

.my-custom-popup-style.mfp-removing .zoom-anim-dialog {
  -webkit-transform: scale(0.8);
  -moz-transform: scale(0.8);
  -ms-transform: scale(0.8);
  -o-transform: scale(0.8);
  transform: scale(0.8);
  opacity: 0;
}

.my-custom-popup-style.mfp-bg {
  background-color: #313F50 !important;
  opacity: 0;
  -webkit-transition: opacity 0.3s ease-out;
  -moz-transition: opacity 0.3s ease-out;
  -o-transition: opacity 0.3s ease-out;
  transition: opacity 0.3s ease-out;
  overflow-y: hidden !important;
}

.my-custom-popup-style.mfp-ready.mfp-bg {
  opacity: 0.8;
}

.my-custom-popup-style.mfp-removing.mfp-bg {
  opacity: 0;
}

/* Дополнительные стили для содержимого попапа */
.my-custom-popup-style .popup-img {
  width: 150px;
  border-radius: 50%;
  height: 150px;
  object-fit: cover;
}
.my-custom-popup-style .popup-img-special {
  width: 150px;
  border-radius: 50%;
  height: 150px;
  object-fit: scale-down;
}
.my-custom-popup-style .popup-flex {
  display: flex;
  justify-content: space-around;
}

.my-custom-popup-style .popup-direction {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  width: 50%;
}

.my-custom-popup-style .popup-desc {
  font-size: 14px;

}

.popup-with-zoom-anim {
  display: flex;
  text-decoration: none;
  color: white;
  transition: all 0.3s ease-in;
  flex-direction: column;
  align-items: center;
  text-align: center;
}

.popup-with-zoom-anim2 {
  display: flex;
  text-decoration: none;
  color: white;
  transition: all 0.3s ease-in;
  flex-direction: column;
  align-items: center;
  text-align: center;
}

.popup-with-zoom-anim:hover {
  text-decoration: none;
  color: #D85121;
  transition: all 0.3s ease-in;
  -webkit-text-decoration: none;
  -webkit-color: #D85121;
  -webkit-transition: all 0.3s ease-in;
}

.popup-with-zoom-anim2:hover {
  text-decoration: none;
  color: #D85121;
  transition: all 0.3s ease-in;
  -webkit-text-decoration: none;
  -webkit-color: #D85121;
  -webkit-transition: all 0.3s ease-in;
}

.become-delegate:hover {
  text-decoration: none;
  color: #D85121;
  transition: all 0.3s ease-in;
  cursor: pointer;
  -webkit-text-decoration: none;
  -webkit-color: #D85121;
  -webkit-transition: all 0.3s ease-in;
  -webkit-cursor: pointer;
}

.download-agenda:hover {
  text-decoration: none;
  color: #D85121;
  transition: all 0.3s ease-in;
  cursor: pointer;
  -webkit-text-decoration: none;
  -webkit-color: #D85121;
  -webkit-transition: all 0.3s ease-in;
  -webkit-cursor: pointer;
}

.popup-item {
  width: 180px;
  height: 180px;
  background-color: white; /* Цвет квадрата */
  border-radius: 20px;
  margin-bottom: 10px;
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 40px;
  color: #313F50;
  align-items: flex-end;
  justify-content: center;
  display: flex;
}

.item-1 {
  background-image: url("../images/creditcard.png"); /* Фоновое изображение для первого квадрата */
  background-repeat: no-repeat;
  background-position-y: 40px !important;
  background-position-x: 50% !important;
}

.item-2 {
  background-image: url("../images/paypal.png"); /* Фоновое изображение для второго квадрата */
  background-repeat: no-repeat;
  background-position: top;
  background-position-y: 40px;
}

.item-3 {
  background-image: url("../images/banktransfer.png"); /* Фоновое изображение для третьего квадрата */
  background-repeat: no-repeat;
  background-position: top;
  background-position-y: 15px;
}

.text-popup {
  padding-bottom: 10px;
  font-weight: 400;
  font-size: 18px;
  line-height: 40px;
}

.white-square {
  width: 597px;
  height: auto;
  background-color: white;
  margin: 0 auto;
  border-radius: 20px;
}

html.mfp-open {
  overflow: hidden;
}

.my-custom-popup-styles .popup-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  height: 100%;
}

.form-group {
  display: flex;
  flex-direction: column;
  padding-left: 30px;
  padding-right: 30px;
  border-radius: 20px !important;
}

.form-group1 {
  display: flex;
  flex-direction: column;
  justify-content: flex-start; /* Align child elements to the left */
  padding-left: 30px;
  padding-right: 30px;
  border-radius: 20px !important;
  margin-top: 10px;
}
.galochka {
  font-size: 16px;
  display: flex;
  align-items: center;
}
.custom-checkbox{
  margin-top: 0 !important;
}

.form-bank div input {
  height: 30px;
  border: 1px solid #313F50;
}

.form-bank div label input {
  height: 15px;
  border: 1px solid #313F50;
}

.custom-checkbox {
  height: 20px; /* Set the desired height */
  width: 20px; /* Set the desired width */
  margin-top: 15px;
}

.register-button {
  margin-left: 30px;
  width: 150px;
  height: 40px;
  background: grey;
  margin-top: 20px;
  border: 0px solid #D85121;
  margin-bottom: 30px;
  color: #ffffff;
  display: flex;
  justify-content: center;
  align-items: center;
}
.canister-button {
  margin-left: 30px;
  width: 150px;
  height: 40px;
  background: #D85121;
  margin-top: 20px;
  border: 0px solid #D85121;
  margin-bottom: 30px;
  color: #ffffff;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}
.playerbox {
  position: relative;
  overflow: hidden;
  /*width: 100%;*/
  /*padding-top: 56.25%;*/
  /*padding: 30px;*/
  /*height: 100%;*/
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #04044C;
  color: #ffffff;
}
.action-button-register{
  /*margin-top: 10px;*/
  margin-bottom: 40px;
  background-color: #D85121;
  color: #fff;
  border: none;
  padding: 18px 36px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 20px;
  cursor: pointer;
  border-radius: 5px;
}
.action-button-agenda{
  margin-bottom: 40px;
  background-color: #D85121;
  color: #fff;
  border: none;
  padding: 18px 36px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 20px;
  cursor: pointer;
  border-radius: 5px;
}
.videobox {
  width: 100%;
  padding: 10px;
}

.player {
  /*top: 0;*/
  /*left: 0;*/
  /*position: absolute;*/
  width: 1000px;
  height: 450px;
  /*padding: 20px;*/
  background-color: #16213e;
  /*border: 5px solid #3498db;*/
  /*border-radius: 2px;*/
  overflow: hidden;
  /*box-shadow:*/
  /*        10px 0px 20px rgba(52, 152, 219, 0.3),  !* Тень справа *!*/
  /*        -10px 0px 20px rgba(52, 152, 219, 0.3), !* Тень слева *!*/
  /*        0px 10px 20px rgba(52, 152, 219, 0.3),  !* Тень снизу *!*/
  /*        0px -10px 20px rgba(52, 152, 219, 0.3); !* Тень сверху *!*/
}
.responsive-iframe {
  width: 100%;
  height: 100%;
}

.react-player {
  border: none;
}


.register-button.active {
  background-color: #D85121; /* Синий цвет, когда чекбокс отмечен */
}

.register-button.inactive {
  background-color: grey; /* Серый цвет, когда чекбокс не отмечен */
  cursor: not-allowed;
}

.form-group label {
  color: #313F50;
  font-size: 16px;
  padding-bottom: 10px;
  padding-top: 10px;
}

/*.form-group label:first-child {*/
/*  padding-top: 20px;*/
/*}*/

.scroll-container {
  max-height: 600px;
  overflow-y: auto;
}

.scroll-container::-webkit-scrollbar {
  width: 7px; /* Ширина скроллера */
}

.scroll-container::-webkit-scrollbar-thumb {
  background-color: #D85121; /* Цвет скроллера */
}

.first-name-class {
  padding-top: 30px !important;
}

.form-group select {
  height: 33px;
  border: 1px solid #313F50;
  border-radius: 0;
}
.new-sponsors{
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
}

.new-logo-sponsor {
  display: flex;
  width: 170px;
  object-fit: cover;
}

.scroll-container-wrapper {
  border-radius: 20px;
  overflow: hidden;
}

.scroll-container {
  max-height: 600px;
  overflow-y: auto;
}

.scroll-container::-webkit-scrollbar {
  width: 7px;
}

.scroll-container::-webkit-scrollbar-thumb {
  background-color: #D85121;
}

.close-button {
  position: fixed;
  top: 25px;
  right: 50px;
  width: 60px;
  height: 60px;
  color: #fff;
  text-align: center;
  line-height: 59px;
  font-size: 45px;
  cursor: pointer;
}

.mfp-close-btn-in .mfp-close {
  display: none;
}

.paypal-square {
  width: 597px;
  height: auto;
  background-color: white;
  margin: 0 auto;
  padding: 0;
  border-radius: 20px;
  max-height: 600px;
}

.paypal-container {
  padding-left: 30px;
  padding-right: 30px;
  overflow-y: auto;
}

.paypal-wrapper {
  align-items: center;
  border-bottom: 1px solid #D85121;
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  padding-left: 15px;
  padding-right: 15px;
}

.buy-now {
  background: #D85121;
  width: 200px;
  height: 44px;
  border: 1px solid #D85121;
  color: #ffffff;
}

.price-paypal {
  text-align: left;
  padding-left: 15px;
}

.my-mfp-fade-in .mfp-content {
  opacity: 0;
  transition: opacity 0.3s ease-in-out;
}

.my-mfp-fade-in.mfp-ready .mfp-content {
  opacity: 1;
}

.my-mfp-fade-in.mfp-removing .mfp-content {
  opacity: 0;
}

.my-mfp-slide-in .mfp-content {
  opacity: 0;
  transform: translateY(100%);
  transition: all 0.4s ease-in-out;
}

.my-mfp-slide-in.mfp-ready .mfp-content {
  opacity: 1;
  transform: translateY(0);
}

.my-mfp-slide-in.mfp-removing .mfp-content {
  opacity: 0;
  transform: translateY(100%);
}

.partners-list {
  padding-top: 80px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  width: 100%;
}

.partners-list li {
  opacity: 1;
  transition: opacity 0.3s ease;
  width: 100%;
  margin-bottom: 20px;
  text-align: center;
}
.partners-list img {
  width: 70%;
  object-fit: cover;
  text-align: center;
}
.partners-list li:hover {
  opacity: 1;
  -webkit-opacity: 1;
}

@media screen and (max-width: 1023px) {
  .desktop-header {
    display: none;
  }

  .desktop-header-conferences {
    display: none;
  }
}

@media screen and (min-width: 1023px) and (max-width: 1111px) {
  .desktop-header {
    max-width: 950px !important;
  }
  .desktop-header-conferences {
    max-width: 950px !important;
  }
}

@media screen and (min-width: 1111px) and (max-width: 1365px) {
  .desktop-header {
    max-width: 1050px !important;
  }
  .desktop-header-conferences {
    max-width: 1050px !important;
  }
  .mobile-header {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 2;
    transition: background-color 0.5s ease-in-out;
  }
}

@media screen and (min-width: 1023px) {
  .mobile-menu {
    display: none;
  }
}

@media screen and (max-width: 1023px) {
  .desktop-header {
    display: none;
  }
  .breadcrumbs {
    display: none;
  }
}

@media screen and (min-width: 767px) and (max-width: 1023px) {
  .mobile-header {
    padding-right: 25px;
    padding-left: 25px;
  }
  .price-sponsors-container {
    display: flex;
  }
}

@media screen and (max-width: 767px) {
  /*.bank-style {*/
  /*  margin-top: 70px;*/
  /*}*/
  .image-wrapper {
    display: none;
  }
  .videobox{
    display: none;
  }
  .line-contact-us {
    display: flex;
    justify-content: center;
  }
  .map-container {
    padding-bottom: calc(20px + (80 - 20) * ((100vw - 320px) / (767 - 320)));
  }
  .mobile-header {
    padding-right: 10px;
    padding-left: 10px;
  }
  .left-arrow-partners {
    left: 12px;
  }
  .popup-with-zoom-anim p {
    font-size: 10px;
    text-align: center;
    font-weight: 400;
    line-height: 12px;
  }

  .popup-with-zoom-anim2 p {
    font-size: 10px;
    text-align: center;
    font-weight: 400;
    line-height: 12px;
  }

  .popup-with-zoom-anim img {
    height: 32px;
    width: 32px;
  }

  .popup-with-zoom-anim2 img {
    height: 32px;
    width: 32px;
  }

  .become-delegate img {
    height: 32px;
    width: 32px;
  }

  .download-agenda img {
    height: 32px;
    width: 32px;
  }

  .upcoming-container {
    padding-left: 20px;
    padding-right: 20px;
  }
  .action-button-register{
    font-size: 14px;
    padding: 15px 25px;
  }
  .action-button-agenda{
    font-size: 14px;
    padding: 15px 25px;
  }

}

.mobile-class {
  height: 54px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.menu {
  background-color: transparent;
  border: none;
  cursor: pointer;
  display: flex;
  padding: 0;
  z-index: 9999;
}



.mobile-nav.open {
  display: flex;
  transform: translateX(0);
  transition: transform 0.3s ease-in;
  z-index: 2;
  background: #313F50;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}

.mobile-list {
  font-family: "Artegra Sans Extended", serif;
  font-weight: 400;
  font-size: 16px;
  line-height: 40px;
  color: #ffffff;
  padding-top: 10px;
}

.mobile-nav {
  display: flex;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  transform: translateX(100%);
  transition: transform 0.3s ease-out;
  background: #313F50;
  justify-content: center;
  align-items: flex-start;
  z-index: 1;
}

.mobile-nav ul a {
  text-decoration: none;
}

nav ul li {
  color: white;
  list-style-type: none;
  font-size: 14px;
}

nav ul {
  display: flex;
}

.mobile-nav ul a {
  text-decoration: none;
}
ul {
  list-style-type: none;
  padding: 0;
  /* margin-top: 70px; */
  text-align: left;
  overflow: hidden;
}

ul.list-navigation {
  list-style-type: none;
  padding: 0;
  margin-top: 70px !important;
  text-align: center;
  overflow: hidden;
}
@media screen and (max-width: 480px) {
  ul.list-navigation {
    list-style-type: none;
    padding: 0;
    margin-top: 0 !important;
    text-align: center;
    overflow: hidden;
  }
}
.list-navigation {
  list-style-type: none;
  padding: 0;
  margin-top: 70px !important;
  text-align: center;
  overflow: hidden;
}

.mobile-nav .list-navigation {
  list-style-type: none;
  padding: 0;
  margin-top: 70px;
  text-align: center;
  overflow: hidden;
}

ul.packages-container {
  overflow: initial; /* Override the overflow property */
}

.list-navigation {
  padding-top: 6.5rem;
  text-align: center;
}

.line-burger {
  display: inline-block;
  width: 280px;
  height: 1px;
  background: #D85121;
  transition: transform 0.3s ease-out;
}

.main-container {
  position: relative;
  height: 100%;
  width: 100%;
  overflow: hidden;
  z-index: 1;
}

.background-single {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  /* background-image: url("../images/background.png"); */
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  z-index: -1;
}

.background-single::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(49, 63, 80, 0.8);
}

.background-stripe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  /* background-image: url("../images/background.png"); */
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  z-index: -1;
  background-color: #313F50;
}
.page-navigation-container {
  background-color: rgba(222, 184, 135, 0.5);
  padding: 0 100px;
  text-align: center;
  transition: top 0.3s ease; /* Добавляем плавное перемещение */
  top: 0; /* Изначальное положение */

}

.page-navigation-container.sticky {
  background-color: rgba(222, 184, 135, 0.9);
  right: 0;
  width: 100%;
  top: 80px; /* Положение сверху */
  position: fixed; /* Используем фиксированное позиционирование */
  z-index: 0; /* Устанавливаем z-index, чтобы панель находилась выше других элементов */
}

.page-navigation {
  display: flex;
  justify-content: right;
  align-items: center;
  list-style-type: none;
  margin: 0;
  padding-top: 10px;
  z-index: 10;
}


.page-navigation li {
  text-align: center;
  display: inline-flex;
  /*margin-right: 20px; !* увеличиваем расстояние между элементами *!*/
}

.page-navigation li:last-child {
  margin-right: 0; /* убираем отступ у последнего элемента */
}

.page-navigation li a {
  transition: color 0.3s, font-size 0.3s;
  text-decoration: none;
  color: mintcream; /* синий цвет ссылок */
  font-size: 18px; /* увеличиваем размер шрифта */
}

.page-navigation li a:hover {
  color: black; /* Изменяем цвет текста при наведении */
  font-size: 150%;
  cursor: pointer; /* Изменяем форму курсора на указатель */
}

/*.background-stripe::after {*/
/*  content: "";*/
/*  position: absolute;*/
/*  top: 0;*/
/*  left: 0;*/
/*  width: 100%;*/
/*  height: 100%;*/
/*  background: rgba(9, 9, 45, 0.8);*/
/*}*/

.background-image {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: url("../images/background-main.webp");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  z-index: -1;
}

.sponsorship-background {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: url("../images/background-sponsorship.png");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  z-index: -1;
}

.sponsorship-background::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(49, 63, 80, 0.8);
}

.about-background {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: url("../images/background-about.png");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  z-index: -1;
}

.about-background::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(9, 9, 45, 0.8);
}

.background-sponsorship {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: url("../images/background-sponsorship.png");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  z-index: 0;
}

.background-sponsorship::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(49, 63, 80, 0.8);
}

.background-image::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(49, 63, 80, 0.8);
}

.background-block {
  position: relative;
  padding-top: 9.3rem;
  color: white;
  z-index: 1;
}

.breadcrumbs {
  margin-top: 20px;
  font-weight: 400;
  font-size: 18px;
  line-height: 22px;
  color: #ffffff;
}

.breadcrumbs-about {
  font-weight: 400;
  font-size: 18px;
  line-height: 22px;
  color: #ffffff;
}

.title-main {
  color: #ffffff;
  font-weight: 500;
  font-size: 53px;
  line-height: 56px;
  border-left: 2px solid #D85121;
  padding-left: 20px;
  margin-bottom: 20px;
  margin-top: 20px;
}

.date-main {
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 22px;
  color: #ffffff;
}

.button-main {
  background: #D85121;
  width: 200px;
  height: 44px;
  border: none;
  font-weight: 500;
  font-size: 18px;
  line-height: 22px;
  color: #ffffff;
  margin-top: 42px;
  cursor: pointer;
}
.button-main:hover {
  background: #C24418; /* Немного темнее при наведении */
  box-shadow: 0 4px 8px rgba(216, 81, 33, 0.3); /* Легкая тень при наведении */
}

/* Мобильная версия кнопки */
@media (max-width: 768px) {
  .button-main {
    width: 100%; /* Растягиваем на всю ширину контейнера */
    max-width: 280px; /* Максимальная ширина для корректного отображения */
    height: 40px; /* Немного меньше высота */
    font-size: 16px; /* Уменьшаем шрифт */
    line-height: 20px;
    margin-top: 30px; /* Меньший отступ сверху */
    border-radius: 4px; /* Легкое скругление углов для мобильной версии */
    display: flex;
    justify-content: center;
    align-items: center; /* Центрирование текста по вертикали */
  }

  /* Активное состояние для тач-устройств */
  .button-main:active {
    background: #B53D15; /* Еще темнее при нажатии */
    transform: scale(0.98); /* Легкий эффект нажатия */
  }
}

/* Дополнительные стили для очень маленьких экранов */
@media (max-width: 320px) {
  .button-main {
    font-size: 15px;
    height: 38px;
    max-width: 260px;
  }
}

/* Вариант с иконкой для мобильной версии */
.button-main-icon {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px; /* Отступ между текстом и иконкой */
}

.button-main-icon svg,
.button-main-icon img {
  width: 16px;
  height: 16px;
}


.left-arrow-venue,
.right-arrow-venue {
  top: 50%;
  z-index: 10;
  transform: translateY(-50%);
}

.left-arrow-venue {
  background-image: url("../images/arrow-left.png");
  left: 10px;
  height: 10px;
  background-repeat: no-repeat;
  width: 120px;
  margin-right: 25px;
  background-size: contain;
}
.left-arrow-partners {
  margin-top: 17px;
  background-image: url("../images/Arrow_blue_left.png");
  right: 10px;
  height: 10px;
  background-repeat: no-repeat;
  width: 120px;
  margin-left: 0px;
  background-size: contain;
}
.right-arrow-partners{
  margin-top: 15px;
  background-image: url("../images/Arrow_blue_right.png");
  right: 10px;
  height: 10px;
  background-repeat: no-repeat;
  width: 120px;
  margin-left: 35px;
  background-size: contain;
}


.right-arrow-venue {
  background-image: url("../images/arrow-right.png");
  right: 10px;
  height: 10px;
  background-repeat: no-repeat;
  width: 120px;
  margin-left: 25px;
  background-size: contain;
}

.left-arrow,
.right-arrow {
  top: 50%;
  z-index: 0;
  transform: translateY(-50%);
}

.left-arrow {
  background-image: url("../images/arrow-left.png");
  left: 10px;
  height: 10px;
  background-repeat: no-repeat;
  width: 120px;
  margin-right: 25px;
  background-size: contain;
}

.right-arrow {
  background-image: url("../images/arrow-right.png");
  right: 10px;
  height: 10px;
  background-repeat: no-repeat;
  width: 120px;
  margin-left: 25px;
  background-size: contain;
}

.right-arrow_events {
  background-image: url("../images/arrow-right.png");
  right: 10px;
  height: 10px;
  background-repeat: no-repeat;
  width: 120px;
  margin-left: 25px;
  background-size: contain;
}

.bottom-arrow {
  background-image: url("../images/Arrow-bottom.png");
  height: 10px;
  background-repeat: no-repeat;
  padding-bottom: 140px;
  right: 0;
  background-position: right;
}

.arrow-container {
  display: flex;
  margin-top: 80px;
  margin-bottom: 100px;
  justify-content: center;
}

.title-about {
  padding-top: 150px;
  font-weight: 500;
  font-size: 48px;
  line-height: 80px;
  text-align: center;
  margin-bottom: 10px;
  margin-top: 0;
}

.line-about {
  display: inline-block;
  width: 250px;
  height: 2px;
  background: #D85121;
  margin-bottom: 8px;
  text-align: center;
}

.line-slider {
  display: inline-block;
  width: 100%;
  height: 2px;
  background: #D85121;
  margin-bottom: 7px;
  text-align: center;
}

.line-container {
  display: flex;
  justify-content: center;
  height: 0;
}

.background-about {
  background-image: url("../images/watermark.png");
  width: 100%;
  height: 100%;
  position: absolute;
  z-index: -1;
  background-repeat: no-repeat;
  background-position: center top;
}

.rectangle-container {
  position: relative;
  max-width: 1400px;
  height: 640px;
  margin: 0 auto;
}

.rectangle-blue {
  box-sizing: border-box; /* добавьте это свойство */
  position: absolute;
  top: 80px;
  left: 40px;
  width: 1320px;
  height: 340px;
  background: #313F50;
  display: flex;
  align-items: center;
  padding: 20px;
}

.rectangle-lightblue {
  position: absolute;
  top: 140px;
  left: 0;
  width: 100%;
  height: 320px;
  border: 1px solid #D85121;
  z-index: -1;
}

.rectangle-blue ul li {
  width: 25%;
  flex-direction: column;
  display: flex;
  font-weight: 400;
  font-size: 18px;
  line-height: 22px;
  color: #ffffff;
  text-align: left;
}

.rectangle-blue ul {
  display: flex;
  justify-content: space-evenly;
}

.rectangle-blue ul li:first-child::before {
  background-image: url("../images/about-list.png");
  content: "";
  display: inline-block;
  background-size: 26px 26px;
  background-repeat: no-repeat;
  width: 44px;
  height: 44px;
}

.rectangle-blue ul li:nth-child(2)::before {
  background-image: url("../images/about-connect.png");
  content: "";
  display: inline-block;
  background-size: 26px 26px;
  background-repeat: no-repeat;
  width: 44px;
  height: 44px;
}

.rectangle-blue ul li:last-child::before {
  background-image: url("../images/about-people.png");
  content: "";
  display: inline-block;
  background-size: 26px 26px;
  background-repeat: no-repeat;
  width: 44px;
  height: 44px;
}
.parent_sponsorship{
  margin: 20px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
  gap: 10px;
}
.photo_sponsor{
  width: calc(40% - 10px);
  height: auto;
}
.rectangle-conference {
  width: 583px;
  height: 675px;
  background: #313F50;
  position: relative;
  z-index: 1; /* Перемещаем блок наверх */
}

.venue-section {
  background-color: #313F50;
  overflow: hidden;
  margin-top: 150px;
}

.venue-title {
  font-size: 36px;
  font-weight: 700;
  color: #ffffff;
  text-align: center;
  padding-top: 2.5rem;
}

.venue-description {
  font-size: 20px;
  font-weight: 400;
  color: white;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
}
.address-line {
  margin-right: 10px; /* Регулируйте отступ между адресом и полосой */
}

.blue-line {
  height: 40px; /* Высота полосы */
  width: 2px; /* Ширина полосы */
  background-color: deeppink; /* Цвет полосы */
  margin-right: 10px; /* Регулируйте отступ между полосой и кнопкой */
}

.how-to-get-button {
  margin-left: 5px;
  font-weight: 400;
  background-color: blue; /* Цвет кнопки */
  font-size: 24px;
  color: white; /* Цвет текста на кнопке */
  padding: 14px; /* Регулируйте отступ вокруг текста кнопки */
  border: none;
  cursor: pointer;
}

.venue-btn {
  width: 200px;
  height: 44px;
  color: white;
  background: #D85121;
  align-items: center;
  border: 1px solid #D85121;
}

.title-gallery {
  display: flex;
  color: white;
  font-weight: 500;
  font-size: 48px;
  line-height: 60px;
  flex-direction: column-reverse;
  align-items: center;
  margin-bottom: 10px;
}

.section-gallery {
  background: #313F50;
  overflow: hidden;
}

.swiper-container {
  width: 100%;
  height: 400px;
  padding-top: 5rem;
  padding-bottom: 10rem;
  position: relative;
}

.swiper-slide {
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  position: relative; /* Замените на relative */
  z-index: 1; /* Добавьте свойство z-index для перекрытия других карточек */
}

.swiper-slide:not(.swiper-slide-active) {
  transform: scale(0.8) !important;
  opacity: 1 !important;
  transition: none !important;
}

.swiper-slide:not(.swiper-slide-active) > * {
  pointer-events: none;
}

.swiper-slide:not(.swiper-slide-active)::before {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: none;
  z-index: 1;
}

.custom-swiper-button {
  width: 132px;
  height: 8px;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  position: absolute;
  top: 98%;
  transform: translateY(-50%);
  z-index: 2;
}

.custom-swiper-button-next {
  right: 10px;
  background-image: url("../images/arrow-right.png");
}

.custom-swiper-button-prev {
  left: 10px;
  background-image: url("../images/arrow-left.png");
}

.swiper-pagination {
  bottom: 0;
  display: flex;
  justify-content: center;
  gap: 4px;
  margin-top: 20px;
}

.swiper-pagination-bullet {
  width: 119px;
  height: 2px;
  background-color: #ffffff;
  opacity: 1;
}

.swiper-pagination-bullet-active {
  background-color: #000;
}

.swiper-button-next,
.swiper-button-prev {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  z-index: 2;
}

.swiper-button-next {
  right: 0;
}

.swiper-button-prev {
  left: 0;
}
.swiper-flex {
  display: flex;
}
.swiper-button-next:after,
.swiper-button-prev:after {
  padding-top: 50%;
}

.opacity {
  padding-top: 70px;
  opacity: 0;
}

.line-container {
  display: flex;
  justify-content: center;
  height: 0;
}

.key-container {
  display: flex;
  justify-content: space-between;
  color: white;
  position: relative;
}
.key-container-block {
  width: 45%;
  margin: 0 auto;
}
.key-container-block ul {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.key-block {
  display: flex;
  gap: 15px;
  align-items: center;
  width: 70%;
}

.key-block img {
  margin-top: 2.9rem;
  width: 30px;
  height: 32px;
}
.key-1::before {
  /* background-image: url("../images/key1.png"); */
  content: "";
  display: inline-block;
  background-size: 26px 26px;
  background-repeat: no-repeat;
  width: 33px;
  height: 25px;
}

.key-2::before {
  background-image: url("../images/key2.png");
  content: "";
  display: inline-block;
  background-size: 26px 26px;
  background-repeat: no-repeat;
  width: 33px;
  height: 25px;
}

.key-3::before {
  background-image: url("../images/key3.png");
  content: "";
  display: inline-block;
  background-size: 26px 26px;
  background-repeat: no-repeat;
  width: 33px;
  height: 25px;
}

.key-4::before {
  background-image: url("../images/key4.png");
  content: "";
  display: inline-block;
  background-size: 26px 26px;
  background-repeat: no-repeat;
  width: 33px;
  height: 25px;
}

.key-5::before {
  background-image: url("../images/key5.png");
  content: "";
  display: inline-block;
  background-size: 26px 26px;
  background-repeat: no-repeat;
  width: 33px;
  height: 25px;
}

.key-6::before {
  background-image: url("../images/key6.png");
  content: "";
  display: inline-block;
  background-repeat: no-repeat;
  width: 33px;
  height: 25px;
  background-size: 26px 26px;
}

@media screen and (min-width: 1023px) and (max-width: 1111px) {
  .container {
    max-width: 950px;
  }
}

@media screen and (min-width: 1111px) and (max-width: 1365px) {
  .container {
    max-width: 1050px;
  }
}

.container-conference {
  display: flex;
  max-width: 1400px;
  margin: 0 auto;
  width: 100%;
  position: relative;
}

.add-class-container {
  box-sizing: border-box;
}

.title-conference {
  padding-top: 150px;
  font-weight: 500;
  font-size: 48px;
  line-height: 60px;
  text-align: left;
  margin-bottom: 10px;
  margin-top: 0;
  width: 60%;
  color: #313F50;
}

.line-conference {
  display: inline-block;
  width: 171px;
  height: 2px;
  background: #D85121;
  margin-bottom: 8px;
  text-align: left;
}

.line-conference-container {
  display: flex;
  height: 0;
}

.conference-text {
  width: 70%;
}

.list-conference-photo {
  list-style: none;
  padding: 0;
  margin: 0;
  position: absolute;
  top: 17%;
  transform: translateX(-24%);
  z-index: 2;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  width: 671px;
}

.list-conference-photo li {
  flex-basis: 49%; /* Ширина элемента списка */
  box-sizing: border-box;
  text-align: center;
  padding: 5px;
}

.list-conference-photo li img {
  max-width: 100%;
  width: 318px;
  height: 231px;
  object-fit: cover;
}

.section-about {
  overflow: hidden;
}

.upcoming-section {
  background: #313F50;
  color: #ffffff;
}

.upcoming-title {
  font-style: normal;
  font-weight: 500;
  font-size: 48px;
  line-height: 60px;
  color: #ffffff;
  text-align: center;
  padding-top: 50px;
  margin-bottom: 10px;
}

#months {
  justify-content: space-between;
  display: flex;
  margin-bottom: 5rem;
}

.month {
  font-size: 14px;
  text-align: center;
  width: 100px;
  position: relative;
  cursor: pointer;
}

.slider-container {
  margin-top: 6rem;
  margin-bottom: 2.5rem;
}

#slider {
  height: 1px;
  background-color: white;
  max-width: 1550px;
  top: -10px;
  transition: left 0.3s ease-in-out;
  margin: 0 auto;
}

#slider-conference {
  height: 1px;
  background-color: #313F50;
  max-width: 1550px;
  top: -10px;
  transition: left 0.3s ease-in-out;
  margin: 0 auto;
}

.control-panel {
  font-size: 16px;
  color: black;
  background-color: white;
  border-radius: 5px;
  width: fit-content;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

#loader {
  z-index: 1;
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(155, 155, 155, 0.7);
}

.clickable {
  cursor: pointer;
}

.disabled {
  cursor: not-allowed;
  color: lightgray;
}

.blue-circle {
  position: absolute;
  background-image: url("../images/blue-circle.png");
  background-repeat: no-repeat;
  width: 44px;
  height: 44px;
  bottom: -21px;
  left: 0;
  transform: translateX(-50%);
  transition: left 0.3s ease-in-out;
}

.content {
  display: none;
  flex-wrap: wrap;
}

.content.active {
  display: block;
  width: 50%;
}

.container-content {
  width: 50%;
  display: flex;
  justify-content: space-evenly;
  box-sizing: border-box;
  margin-bottom: 5.62rem;
}

.title-content {
  margin-top: 0;
  font-size: 1.5rem;
  width: 100%;
  font-weight: 500;
}

.description-content {
  font-size: 18px;
  width: 27.75rem;
  font-style: normal;
  font-weight: 300;
  line-height: 21px;
  font-family: "Montserrat", "sans-serif";
}

.data-content {
  font-size: 16px;
  width: 35%;
}

.btn-content {
  width: 12.5rem;
  height: 2.75rem;
  background: #D85121;
  border: none;
  color: #fff;
  font-size: 1.125rem;
  font-family: Montserrat, sans-serif;
  font-weight: 500;
  cursor: pointer;
}

.special-class {
  width: 100%;
  box-sizing: border-box;
  margin-bottom: 5.62rem;
}

.arrow-events {
  display: flex;
  justify-content: center;
  margin-top: 3rem;
  padding-bottom: 3rem;
  align-items: flex-end;
}

.parent {
  margin-top: 5rem;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-template-rows: repeat(1, 1fr);
  grid-column-gap: 20px;
  grid-row-gap: 15px;
}
.parent_sponsorhip {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
  gap: 10px;
}
.child_sponsorship{
  width: calc(33.33% - 10px);
  height: auto;
}

.div1 {
  grid-area: 2 / 1 / 3 / 2;
}
.div2 {
  grid-area: 2 / 2 / 3 / 3;
}
.div3 {
  grid-area: 1 / 3 / 3 / 5;
}
.div4 {
  grid-area: 1 / 1 / 2 / 3;
}
.div5 {
  grid-area: 3 / 3 / 4 / 5;
}
.div6 {
  grid-area: 3 / 1 / 4 / 2;
}
.div7 {
  grid-area: 3 / 2 / 4 / 3;
}

.container-special {
  max-width: 1320px;
}

.more-gallery {
  color: #313F50;
  font-size: 1.125rem;
  font-family: Montserrat, sans-serif;
  font-weight: 500;
  line-height: 3.75rem;
  text-decoration-line: underline;
}

.more-container {
  padding-top: 3.75rem;
  padding-bottom: 9.37rem;
  text-align: center;
}

a {
  text-decoration: none;
}

.about-title {
  padding-top: 161px;
  font-size: 4rem;
  font-weight: 500;
  margin: 0;
}

.about-description {
  padding-top: 60px;
  font-size: 18px;
  line-height: 32px;
  padding-bottom: 166px;
  margin: 0;
  width: 73%;
}

.container-our-vision {
  display: flex;
  border-top: 1px solid #313F50;
}

.vision-first {
  width: 50%;
  display: flex;
  align-items: center;
  border-right: 1px solid #313F50;
}

.vision-second {
  width: 50%;
  text-align: right;
  padding-bottom: 70px;
  padding-top: 70px;
}

.vision-third {
  width: 50%;
  text-align: left;
  padding-bottom: 70px;
  padding-top: 70px;
  border-right: 1px solid #313F50;
}

.vision-fourth {
  width: 44%;
  display: flex;
  align-items: center;
  transform: translateX(121px);
  -webkit-transform: translate(121px);
}

.title-vision {
  font-weight: 500;
  font-size: 48px;
  display: flex;
  flex-direction: column-reverse;
}

.title-vision:before {
  content: "";
  position: absolute;
  width: 121px;
  height: 2px;
  background: #D85121;
  top: 110px;
}

.presentation-title {
  font-weight: 500;
  font-size: 48px;
}

.presentation-title:before {
  content: "";
  position: absolute;
  width: 121px;
  height: 2px;
  background: #D85121;
  top: 110px;
}

.description-vision {
  width: 73%;
  line-height: 32px;
}

.line-presentation {
  padding-bottom: 50px;
}

.item-extra-mobile {
  display: none;
}

.sponsorship-block {
  position: relative;
}

.packages-item:not(:last-child) {
  margin-right: 20px;
}
.packages-item-conference:not(:last-child) {
  margin-right: 20px;
}

.packages-item {
  border: 2px solid #D85121;
  width: 25%;
  transition: all 0.3s ease-in;
  text-align: center;
}
.packages-item-conference {
                /* 4 элемента в строке */
  box-sizing: border-box;     /* Чтобы padding учитывался в ширине */
  padding: 20px;              /* Отступы внутри элемента */
  text-align: center;
  transition: all 0.3s ease-in;
}

.packages-container {
  display: flex;
  gap: 30px;
  justify-content: center;
  padding-top: 80px;
  padding-bottom: 40px;
}

.line-sponsorship {
  display: inline-block;
  width: 100%;
  height: 2px;
  background: #D85121;
  margin-bottom: 8px;
  text-align: center;
}

.sponsor-title {
  text-align: center;
  font-size: 24px;
  font-weight: 700;
  color: #173d50;
  transition: all 0.3s ease-in;
}
.sponsor-title-conference {
  text-align: center;
  font-size: 24px;
  font-weight: 700;
  color: #173d50;
  transition: all 0.3s ease-in;
}

.sponsor-price {
  text-align: center;
  font-size: 30px;
  font-weight: 600;
  color: #313F50;
  margin-top: 20px;
  transition: all 0.3s ease-in;
}

.sponsor-description {
  color: rgba(23, 61, 80, 0.4);
  text-align: center;
  font-size: 14px;
  font-weight: 400;
  margin-bottom: 20px;
  transition: all 0.3s ease-in;
}

.sponsors-list li {
  font-size: 14px;
  color: #313F50;
  font-weight: 400;
  margin-bottom: 10px;
  transition: all 0.3s ease-in;
}
.sponsors-list-btel li {
  font-size: 14px;
  color: #313F51;
  font-weight: 400;
  margin-bottom: 10px;
  transition: all 0.3s ease-in;
}

.sponsors-list {
  min-height: 40px;
  padding-left: 15px;
  list-style: none;
  position: relative;
  padding-top: 10px;
  padding-bottom: 30px;
}

.sponsors-list-btel {
  min-height: 40px;
  padding-left: 30px;
  list-style: none;
  position: relative;
  padding-top: 10px;
  padding-bottom: 30px;
}
.sponsors-list-btel li::marker {
  color: #313F51;
  font-size: 1.2em;
}

.sponsors-list li {
  padding-left: 15px;
  background-image: url("../images/eclipse.png");
  background-repeat: no-repeat;
  background-position: left 4px;
}

.sponsors-list-btel li {
  padding-left: 15px;
  background-repeat: no-repeat;
  background-position: left 4px;
}
sponsors-list-btel li:hover {
  color: #f0f0f0;
}

.sponsors-button {
  margin-bottom: 30px;
  font-size: 18px;
  font-weight: 500;
  line-height: 24px;
  background: #D85121;
  color: white;
  width: 201px;
  height: 44px;
  border: none;
  border-radius: 22px; /* Это даст кнопке полностью закругленные углы. Меняй значение для разного радиуса */
  cursor: pointer; /* Чтобы показать, что кнопка кликабельна */
  transition: background-color 0.3s ease;
}
.sponsors-button:hover {
  background: #343bc2; /* Сделай кнопку немного темнее при наведении */
}

.packages-item:hover {
  background: #313F50;
  transition: all 0.3s ease-in;
  -webkit-background: #313F50;
  -webkit-transition: all 0.3s ease-in;

}
.packages-item-conference:hover {
  background: #313F50;
  transition: all 0.3s ease-in;
  -webkit-background: #313F50;
  -webkit-transition: all 0.3s ease-in;
}
.packages-item:hover > .sponsor-title {
  color: white;
  transition: all 0.3s ease-in;
  -webkit-color: white;
  -webkit-transition: all 0.3s ease-in;
}
.packages-item-conference:hover > .sponsor-title-conference {
  color: white;
  transition: all 0.3s ease-in;
  -webkit-color: white;
  -webkit-transition: all 0.3s ease-in;
}
.packages-item-conference:hover > .btel-include {
  color: #313F51 !important;
  transition: all 0.3s ease-in;
  -webkit-color: white;
  -webkit-transition: all 0.3s ease-in;
}

.btel-include {
  color: rgb(49, 63, 81);
}
.btel-package:hover {
  background-color: #313F51; /* Пример */
}

.btel-package:hover .sponsor-title-conference,
.btel-packagee:hover .sponsor-price,
.btel-package:hover .includes-title,
.btel-package:hover .sponsors-list-btel li {
  color: #f0f0f0;
}
.packages-item:hover > .sponsor-price {
  color: white;
  transition: all 0.3s ease-in;
  -webkit-color: white;
  -webkit-transition: all 0.3s ease-in;
}
.packages-item-conference:hover > .sponsor-price {
  color: white;
  transition: all 0.3s ease-in;
  -webkit-color: white;
  -webkit-transition: all 0.3s ease-in;
}

.packages-item:hover > .sponsor-description {
  color: rgba(255, 255, 255, 0.4);
  transition: all 0.3s ease-in;
  -webkit-color: rgba(255, 255, 255, 0.4);
  -webkit-transition: all 0.3s ease-in;
}
.packages-item-conference:hover > .sponsor-description {
  color: rgba(255, 255, 255, 0.4);
  transition: all 0.3s ease-in;
  -webkit-color: rgba(255, 255, 255, 0.4);
  -webkit-transition: all 0.3s ease-in;
}

.packages-item:hover > .sponsors-list li {
  color: white;
  transition: all 0.3s ease-in;
  -webkit-color: white;
  -webkit-transition: all 0.3s ease-in;
}

.packages-item:hover > .sponsors-list-btel li {
  color: #313F50;
  transition: all 0.3s ease-in;
  -webkit-color: white;
  -webkit-transition: all 0.3s ease-in;
}

.packages-item-conference:hover > .sponsors-list li {
  color: white;
  transition: all 0.3s ease-in;
  -webkit-color: white;
  -webkit-transition: all 0.3s ease-in;
}
.packages-item-conference:hover > .sponsors-list-btel li {
  color: #f0f0f0;
  transition: all 0.3s ease-in;
  -webkit-color: white;
  -webkit-transition: all 0.3s ease-in;
}
.addition-service {
  width: 60%;
  background: #04044c;
  margin-right: 100px;
}

.addition-contact {
  width: 40%;
}

.addition-container {
  display: flex;
  justify-content: center;
}

.addition-list {
  display: flex;
}

.addition-service-first {
  width: 50%;
  margin-top: 40px;
  list-style: none;
  padding: 0;
}

.addition-service-first li {
  margin-top: 30px;
  color: white;
  text-align: left;
  margin-bottom: 10px; /* или другой подходящий вам отступ */

}

.addition-title {
  text-align: center;
  font-size: 24px;
  color: white;
  margin-top: 50px;
}

.addition-btn {
  cursor: pointer;
  font-size: 18px;
  font-weight: 500;
  width: 200px;
  height: 44px;
  background: #D85121;
  border: none;
  color: white;
}

.addition-btn-container {
  display: flex;
  justify-content: center;
  margin-top: 60px;
  margin-bottom: 60px;
}

.addition-border-class {
  border-right: 1px solid #D85121;
}

.addition-service-first li {
  margin-left: 20px;
  padding-left: 20px;
  /*background-image: url("../images/eclipse.png");*/
  background-repeat: no-repeat;
  background-position: left center;
  font-size: 16px;
  padding-right: 10px;
}

.addition-contact-title {
  color: #313F50;
  font-size: 24px;
  font-style: normal;
  font-weight: 500;
  margin-top: 40px;
  text-align: center;
}

.addition-contact-description {
  text-align: center;
  color: #313F50;
  font-size: 18px;
}

.become-sponsor-title {
  color: #313F50;
  font-size: 24px;
  font-weight: 500;
  padding-top: 50px;
}

.addition-contact {
  text-align: center;
}

.addition-name {
  color: #313F50;
  font-size: 16px;
}

.contact-photo {
  border-radius: 50%;
}

.addition-position {
  color: #313F50;
  font-size: 14px;
}

.phone-sponsorship {
  color: #313F50;
  font-size: 14px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.email-sponsorship {
  color: #313F50;
  font-size: 14px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.phone-sponsorship::before {
  content: "";
  display: inline-block;
  width: 16px; /* Ширина иконки */
  height: 16px; /* Высота иконки */
  background-image: url("../images/phone.png");
  background-size: contain; /* Масштабирование иконки */
  background-repeat: no-repeat;
  margin-right: 5px; /* Отступ между иконкой и текстом */
}

.email-sponsorship::before {
  content: "";
  display: inline-block;
  width: 19px;
  height: 16px;
  background-image: url("../images/mail.png");
  background-size: cover;
  background-repeat: no-repeat;
  margin-right: 5px;
}

.list-partners {
  display: flex;
  align-items: center;
}

.list-partners li img {
  max-height: 150%;
  max-width: 150%;
}

.list-partners li {
  width: 16.6%;
  text-align: center;
  background: rgba(110, 188, 255, 0.27);
  height: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.list-partners-padding {
  padding-top: 80px;
}

.list-partners li:not(:last-child) {
  margin-right: 21px;
}

.empty-sponsor {
  opacity: 0;
}

.diagram-container {
  display: flex;
  justify-content: center;
  padding-top: 80px;
  padding-bottom: 150px;
}

.diagram-mobile {
  display: none;
}

.diagram-tablet {
  display: none;
}

@media screen and (max-width: 1023px) {
  .breadcrumbs-about {
    display: none;
  }
  .bottom-arrow {
    display: none;
  }
}

@media screen and (min-width: 319px) and (max-width: 767px) {
  .page-navigation-container {
    display: none;
  }
  .page-navigation-container.sticky {
    display: none;
  }
  .right-arrow-venue {
    width: 80px;
    background-position: center;
    background-image: url("../images/arrow-right-mobile.png");
  }
  .left-arrow-venue {
    width: 80px;
    background-position: center;
    background-image: url("../images/arrow-left-mobile.png");
  }
  .left-arrow {
    width: 80px;
    background-position: center;
    background-image: url("../images/arrow-left-mobile.png");
  }
  .right-arrow {
    width: 80px;
    background-position: center;
    background-image: url("../images/arrow-right-mobile.png");
  }
  .right-arrow_events {
    width: 80px;
    background-position: center;
    background-image: url("../images/arrow-right-mobile.png");
  }
  .arrows-swiper_container {
    margin-top: 0 !important;
  }
  .main-swiper_container {
    height: 400px !important;
    padding-top: 6rem !important;
  }
  .custom-swiper-button-next {
    width: 42px;
    background-image: url("../images/arrow-right-mobile.png");
  }
  .price-sponsors-container {
    display: flex;
  }

  .custom-swiper-button-prev {
    width: 42px;
    background-image: url("../images/arrow-left-mobile.png");
  }
  .swiper-container {
    height: 200px;
  }
  .key-container ul li {
    margin-bottom: 20px;
    text-align: left;
  }
  .title {
    padding-top: 50px;
  }
  .title-about-single {
    padding-top: 60px !important;
  }
  .diagram-title {
    padding-top: 15px !important;
  }
  .email-sponsorship {
    font-size: 10px;
  }
  .phone-sponsorship {
    font-size: 10px;
  }
  .addition-position {
    font-size: 10px;
  }
  .addition-name {
    font-size: 14px;
  }
  .become-sponsor-title {
    padding-top: 15px;
    font-size: 16px;
  }
  .addition-contact-description {
    font-size: 14px;
  }
  .addition-contact-title {
    font-size: 20px;
    margin-bottom: 10px;
  }
  .sponsor-price {
    font-size: 16px;
  }
  .sponsor-title {
    font-size: 16px;
  }
  .sponsor-title-conference {
    font-size: 16px;
  }
  .diagram-desktop {
    display: none;
  }
  .diagram-mobile {
    display: block;
  }
  .list-partners li:nth-child(odd) {
    margin-right: 10px !important;
  }
  .list-partners li:not(:last-child) {
    margin-right: 0;
  }
  .list-partners-padding {
    padding-top: 0;
  }
  .list-partners {
    flex-wrap: wrap;
    margin-top: 0;
    margin-bottom: 0;
  }

  .list-partners li img {
    max-height: 150%;
    max-width: 150%;
  }

  .list-partners li {
    width: 48%;
    margin-bottom: 10px;
  }

  .sponsors-list {
    padding-left: 15px;
  }
  .sponsors-list-btel {
    padding-left: 15px;
  }
  .addition-contact {
    width: 100%;
  }
  .item-extra-mobile {
    display: block;
  }
  .addition-service {
    display: none;
  }
  .addition-container {
    flex-direction: column;
  }
  .packages-container {
    padding-top: 0;
  }
  .packages-item {
    width: 100% !important;
    margin-top: 23px;
  }
  .packages-item-conference {
    width: 100% !important;
    margin-top: 23px;
  }
  .packages-container {
    flex-direction: column;
  }
  .mobile-our-mission {
    flex-direction: column-reverse !important;
  }
  .title-vision:before {
    width: 122px;
    top: 67px;
  }
  .description-vision {
    width: 100%;
    font-size: 14px;
    line-height: 32px;
  }
  .title-vision {
    font-size: 20px;
    margin-top: 40px;
  }
  .vision-third img {
    width: 100%;
  }
  .vision-second img {
    width: 100%;
  }
  .vision-third,
  .vision-second,
  .vision-fourth {
    width: 100%;
    -webkit-transform: translate(0);
  }
  .vision-third {
    border-right: 0;
    padding-top: 0px;
  }
  .vision-first {
    width: 100%;
    border-right: 0;
  }
  .vision-second {
    padding-top: 0;
    padding-bottom: 40px;
  }
  .container-our-vision {
    flex-direction: column;
  }
  .bottom-arrow {
    display: none;
  }
  .presentation-title {
    padding-top: 0 !important;
  }
  .about-description {
    font-size: 14px;
    width: 100%;
    line-height: 20px;
  }
  .line-mobile {
    position: absolute;
    width: 107px;
    height: 2px;
    background: #D85121;
    bottom: 47%;
  }
  .breadcrumbs-about {
    display: none;
  }
  .about-title {
    font-size: 20px;
    padding-top: 40px;
  }
  .title-main {
    font-size: 24px;
    line-height: 32px;
  }

  .button-main {
    margin-top: 0;
  }

  .breadcrumbs {
    font-size: 14px;
  }

  .date-main {
    font-size: 14px;
  }

  .arrow-container {
    justify-content: space-between;
  }
  .single-arrow-container {
    margin: 20px 0 !important;
  }
  /*.left-arrow {*/
  /*  margin-left: 30px;*/
  /*}*/

  /*.right-arrow {*/
  /*  margin-right: 30px;*/
  /*}*/

  .rectangle-blue ul {
    flex-direction: column;
  }

  /*.rectangle-blue {*/
  /*    width: 280px;*/
  /*    height: 570px;*/
  /*}*/
  .rectangle-blue {
    position: static;
    width: calc(
            100% - 30px
    ); /* использование calc снова, чтобы создать отступы с обеих сторон */
    height: auto;
    padding: 20px;
    margin: auto; /* auto отцентрует элемент */
  }

  .rectangle-blue ul li {
    width: 100%;
    font-size: 14px;
    margin-bottom: 20px;
  }

  .rectangle-container {
    max-width: 100%;
    height: auto;
    margin-bottom: 40px;
  }

  .background-about {
    background-image: url("../images/watermark-mobile.png");
  }

  .rectangle-lightblue {
    position: absolute;
    width: calc(100% - 2px);

    margin: auto;
    padding-bottom: 20px;
  }

  .line-container {
    padding-bottom: 25px;
  }

  .rectangle-blue ul li:first-child::before {
    width: 100%;
    height: 44px;
    background-position: center;
  }
  .rectangle-blue ul li:nth-child(2)::before {
    width: 100%;
    height: 44px;
    background-position: center;
  }
  .rectangle-blue ul li:last-child::before {
    width: 100%;
    height: 44px;
    background-position: center;
  }
  .title-conference {
    margin-top: 60px;
    font-size: 20px;
    line-height: 32px;
    text-align: center;
    width: 100%;
    padding-top: 0px;
  }
  .line-conference-container {
    justify-content: center;
  }
  .conference-text {
    width: 100%;
    font-size: 14px;
    padding-top: 20px;
  }
  .container-conference {
    flex-direction: column;
  }
  .list-conference-photo {
    width: 100%;
    transform: translateX(0%);
    top: 75%;
    position: unset;
    padding-top: 30px;
    padding-bottom: 30px;
    /*opacity: 0;*/
  }
  .rectangle-conference {
    display: none;
  }
  .list-conference-photo li img {
    width: 100%;
    height: auto;
  }
  .add-class-container {
    padding-right: 20px;
    padding-left: 20px;
  }
  .rectangle-photos:before {
    content: "";
    height: calc(100%);
    width: 70%;
    background-color: #313F50;
    position: absolute;
    right: 0;
    top: 0;
  }
  .rectangle-photos {
    position: relative;
  }
  .upcoming-title {
    font-size: 20px;
    line-height: 32px;
  }
  .slider-container {
    margin-top: 2rem;
  }
  .content {
    flex-direction: column;
  }
  .month {
    font-size: 8px;
  }
  .container-content {
    width: 100%;
    flex-direction: column;
    margin-bottom: 30px;
  }
  .blue-circle {
    background-image: url("../images/blue-circle-mobile.png");
    transform: translateX(-30%);
    bottom: -33px;
  }
  .data-content {
    text-align: left;
    padding-bottom: 10px;
    font-size: 16px !important;
    width: 100%;
  }
  .title-content {
    font-size: 16px;
    line-height: 32px;
    width: 100%;
  }
  .description-content {
    width: 100%;
    line-height: 21px;
    font-size: 14px;
  }
  .arrow-events div {
    font-size: 16px;
  }
  .container-special {
    max-width: 100%;
  }
  .parent {
    grid-column-gap: 10px;
    grid-row-gap: 10px;
    overflow-x: hidden;
    margin-top: 30px;
  }
  .image-parent4 {
    width: 100%;
    height: 75px !important;
    object-fit: cover;
  }
  .image-parent4-sponsorship {
    width: 100%;
    height: 75px !important;
    object-fit: cover;
  }

  .image-parent3,
  .image-parent2,
  .image-parent7,
  .image-parent6,
  .image-parent5,
  .image-parent1 {
    width: 100%;
    height: 60px !important;
    object-fit: cover;
  }
  .image-parent3 {
    width: 100%;
    height: 150px !important;
    object-fit: cover;
  }
  .image-parent3-sponsorship,
  .image-parent2-sponsorship,
  .image-parent7-sponsorship,
  .image-parent6-sponsorship,
  .image-parent5-sponsorship,
  .image-parent1-sponsorship {
    width: 100%;
    height: 60px !important;
    object-fit: cover;
  }
  .image-parent3-sponsorship {
    width: 100%;
    height: 150px !important;
    object-fit: cover;
  }
  .parent_sponsorship{
    margin: 10px;
    display: grid;
    align-items: center;
    justify-items: center;
  }
  .photo_sponsor{
    width: calc(100% - 10px);
    height: auto;
  }
  .child_sponsorship{
    width: calc(100% - 10px);
  }
  .more-container {
    padding-bottom: 60px;
    padding-top: 30px;
  }
}

@media screen and (min-width: 767px) and (max-width: 1111px) {

  .page-navigation-container {
    display: none;
  }
  .page-navigation-container.sticky {
    display: none;
  }

  .diagram-tablet {
    display: block;
  }
  .diagram-desktop {
    display: none;
  }
  .list-partners-padding {
    padding-top: 40px !important;
  }
  .list-partners {
    margin-top: 0;
    margin-bottom: 5px;
  }
  .list-partners-padding {
    padding-top: 10px;
  }
  .list-partners li:not(:last-child) {
    margin-right: 5px;
  }
  .email-sponsorship {
    font-size: 10px;
  }
  .phone-sponsorship {
    font-size: 10px;
  }
  .addition-position {
    font-size: 10px;
  }
  .addition-name {
    font-size: 14px;
  }
  .become-sponsor-title {
    font-size: 18px;
  }
  .addition-contact-title {
    margin-bottom: 20px;
    font-size: 36px;
  }
  .addition-contact-description {
    font-size: 16px;
  }
  .addition-contact {
    width: 70%;
  }
  .addition-container {
    justify-content: center;
  }
  .addition-service {
    display: none;
  }
  .sponsors-list li {
    font-size: 12px;
  }
  .sponsors-list-btel li {
    font-size: 12px;
  }
  .sponsor-price {
    font-size: 18px;
  }
  .sponsor-title {
    font-size: 18px;
  }
  .sponsor-title-conference {
    font-size: 18px;
  }

  .add-extra-price {
    margin-top: 20px;
  }
  .sponsors-list {
    padding-left: 15px;
  }
  .sponsors-list-btel {
    padding-left: 15px;
  }
  .packages-item:nth-child(3) {
    margin-right: 0 !important;
  }
  .packages-item-conference:nth-child(3) {
    margin-right: 0 !important;
  }
  .item-extra-mobile {
    display: block;
  }
  .packages-item {
    width: 30%;
    margin-bottom: 40px;
  }
  .packages-item-conference {
    width: 30%;
    margin-bottom: 40px;
  }
  .packages-container {
    flex-wrap: wrap;
    justify-content: center;
    padding-top: 40px;
  }
  .about-description {
    font-size: 14px;
  }
  .about-title {
    font-size: 48px;
    padding-top: 20px;
  }
  .vision-fourth {
    translate: 31px;
    -webkit-transform: translate(31px);
  }
  .vision-third img {
    width: 94%;
  }
  .title-vision {
    font-size: 36px;
  }
  .description-vision {
    width: 97%;
    font-size: 14px;
  }
  .title-vision:before {
    top: 86px;
  }
  .vision-second img {
    width: 94%;
  }
  .about-description {
    width: 97%;
  }
  .title-main {
    font-size: 44px;
  }
  .custom-swiper-button {
    top: 109%;
  }
  .opacity {
    padding-top: 120px;
  }
  .swiper-container {
    height: 400px;
    padding-bottom: 0;
  }
  .rectangle-blue {
    width: calc(100% - 50px);
    position: static;
    margin: 40px auto auto;
    height: 443px;
  }

  .rectangle-blue ul {
    display: flex;
    justify-content: space-evenly;
    flex-wrap: wrap;
    height: 100%;
    align-content: space-around;
  }

  .rectangle-blue ul li {
    width: 47%;
    font-size: 14px;
  }

  .background-about {
    background-image: url("../images/watermark-laptop.png");
  }

  .rectangle-lightblue {
    position: absolute;
    width: calc(100% - 2px);

    margin: auto;
    padding-bottom: 20px;
  }

  .rectangle-container {
    margin-left: 15px;
    margin-right: 15px;
  }

  .list-conference-photo li img {
    width: 174px;
    height: 125px;
  }
  .list-conference-photo {
    width: auto;
    transform: translateX(-11%);
    top: 18%;
  }
  .title-conference {
    font-size: 36px;
    line-height: 60px;
    padding-top: 0;
  }
  .conference-text {
    font-size: 14px;
    width: 100%;
  }
  .add-class-container {
    margin-left: 20px;
    width: 50%;
  }
  .rectangle-conference {
    width: 331px;
    height: 404px;
  }
  .container-conference {
    justify-content: space-between;
  }
  .title-content {
    font-size: 18px;
    width: 100%;
  }
  .container-content {
    flex-direction: column;
    padding-right: 10px;
  }
  .description-content {
    width: 100%;
    font-size: 14px;
  }
  .data-content {
    font-size: 14px !important;
    text-align: left;
    padding-bottom: 10px;
  }
  .parent {
    grid-column-gap: 10px;
    grid-row-gap: 10px;
    overflow-x: hidden;
    margin-top: 30px;
  }
  .image-parent4 {
    width: 100%;
    height: 179px;
    object-fit: cover;
  }
  .image-parent3,
  .image-parent2,
  .image-parent7,
  .image-parent6,
  .image-parent5,
  .image-parent1 {
    width: 100%;
    height: 139px;
    object-fit: cover;
  }
  .image-parent3 {
    width: 100%;
    height: 333px;
    object-fit: cover;
  }
  .parent-sponsorship {
    grid-column-gap: 10px;
    grid-row-gap: 10px;
    overflow-x: hidden;
    margin-top: 30px;
  }
  .image-parent4-sponsorship {
    width: 100%;
    height: 179px;
    object-fit: cover;
  }
  .image-parent3-sponsorship,
  .image-parent2-sponsorship,
  .image-parent7-sponsorship,
  .image-parent6-sponsorship,
  .image-parent5-sponsorship,
  .image-parent1-sponsorship {
    width: 100%;
    height: 139px;
    object-fit: cover;
  }
  .image-parent3-sponsorship {
    width: 100%;
    height: 333px;
    object-fit: cover;
  }
  .more-container {
    padding-bottom: 60px;
    padding-top: 30px;
  }
}

@media screen and (min-width: 997px) and (max-width: 1111px) {
  .rectangle-conference {
    width: 429px;
  }
  .list-conference-photo li img {
    width: 200px;
    height: 139px;
  }
  .list-conference-photo {
    top: 12%;
  }
}
/*ради коммита*/
@media screen and (min-width: 1111px) and (max-width: 1364px) {
  .sponsors-list li {
    font-size: 13px;
  }

  .sponsors-list-btel li {
    font-size: 13px;
  }
  .sponsors-list {
    padding-left: 7px;
  }

  .sponsors-list-btel {
    padding-left: 7px;
  }
  .vision-fourth {
    translate: 31px;
    -webkit-transform: translate(31px);
  }
  .vision-third img {
    width: 94%;
  }
  .title-vision {
    font-size: 36px;
  }
  .description-vision {
    width: 97%;
    font-size: 14px;
  }
  .title-vision:before {
    top: 86px;
  }
  .vision-second img {
    width: 94%;
  }
  .about-description {
    width: 97%;
  }
  .rectangle-blue {
    width: 93%;
  }

  .rectangle-container {
    margin-left: 15px;
    margin-right: 15px;
  }

  .add-class-container {
    margin-left: 15px;
  }

  .rectangle-conference {
    width: 518px;
  }

  .title-conference {
    padding-top: 90px;
  }
  .container-content {
    padding-left: 30px;
    flex-direction: column;
  }
  .data-content {
    text-align: left;
    padding-bottom: 10px;
  }
  .parent {
    grid-column-gap: 10px;
    grid-row-gap: 10px;
    overflow-x: hidden;
    margin-top: 30px;
  }
  .image-parent4 {
    width: 100%;
    height: 179px;
    object-fit: cover;
  }
  .image-parent3,
  .image-parent2,
  .image-parent7,
  .image-parent6,
  .image-parent5,
  .image-parent1 {
    width: 100%;
    height: 139px;
    object-fit: cover;
  }
  .image-parent3 {
    width: 100%;
    height: 333px;
    object-fit: cover;
  }
  .parent-sponsorship {
    grid-column-gap: 10px;
    grid-row-gap: 10px;
    overflow-x: hidden;
    margin-top: 30px;
  }
  .image-parent4-sponsorship {
    width: 100%;
    height: 179px;
    object-fit: cover;
  }
  .image-parent3-sponsorship,
  .image-parent2-sponsorship,
  .image-parent7-sponsorship,
  .image-parent6-sponsorship,
  .image-parent5-sponsorship,
  .image-parent1-sponsorship {
    width: 100%;
    height: 139px;
    object-fit: cover;
  }
  .image-parent3-sponsorship {
    width: 100%;
    height: 333px;
    object-fit: cover;
  }
  .more-container {
    padding-bottom: 60px;
    padding-top: 30px;
  }
}

@media screen and (min-width: 1023px) {
  .line-mobile {
    display: none;
  }
  .br-about {
    display: block;
  }
}

#content-conferences {
  width: 90%;
}

.content-conferences:hover {
  background-size: cover !important;
  background-repeat: no-repeat !important;
  -webkit-background-size: cover !important;
  -webkit-background-repeat: no-repeat !important;
}

.content-conferences img:hover {
  background-size: cover;
  background-repeat: no-repeat;
  -webkit-background-size: cover;
  -webkit-background-repeat: no-repeat;
}


.content-conferences:hover,
.content-conferences:hover .data-content,
.content-conferences:hover .title-content,
.content-conferences:hover .description-content,
.content-conferences:hover .conference-data-btn {
  color: white;
  transition: color 0.25s ease-in-out; /* Добавляем эффект перехода */
  transition-delay: 0.25s; /* Задержка в 0.2 секунды */
  -webkit-color: white;
  -webkit-transition: color 0.25s ease-in-out; /* Добавляем эффект перехода */
  -webkit-transition-delay: 0.25s; /* Задержка в 0.2 секунды */
}
@media (max-width: 769px) {
  .content-conferences:hover,
  .content-conferences:hover .data-content,
  .content-conferences:hover .title-content,
  .content-conferences:hover .description-content,
  .content-conferences:hover .conference-data-btn {
    color: burlywood;
  }
}
.content-conferences:hover .btn-content {
  color: #313F50;
  -webkit-color: #313F50;
}

.content-conferences:hover .line-slider {
  background-color: burlywood;
  -webkit-background-color: white;
}

@media screen and (max-width: 767px) {
  .title-conferences {
    font-size: 20px;
    line-height: 30px;
    padding-top: 6rem;
    text-align: center;
  }

  #months {
    margin-bottom: 2rem;
  }
}

.title-breadcrumbs {
  color: rgba(0, 0, 90, 0.5);
  padding-right: 15px;
}

.description-breadcrumbs {
  color: #313F50;
}

@media screen and (min-width: 767px) and (max-width: 1111px) {
  .title-conferences {
    font-size: 36px;
    padding-top: 60px;
  }
  .upcoming-title {
    font-size: 36px;
  }
  .title-about {
    font-size: 36px;
  }
  .title-packages {
    padding-top: 100px;
  }
}

@media screen and (min-width: 1023px) and (max-width: 1111px) {
  .title-conferences {
    padding-top: 0;
  }
}

/* Contact us */

.contact-us-list li {
  display: flex;
  align-items: flex-start;
  text-align: left;
}

.contact-us-container {
  display: flex;
}
@media screen and (max-width: 767px) {
  .contact-us-container {
    flex-direction: column-reverse;
    gap: 25px;
  }
}

.address-block {
  display: flex;
  width: 58%;
  padding-top: 90px;
}

.form-block {
  display: flex;
  width: 40%;
}

.contact-us-list div p {
  margin-top: 0;
  margin-bottom: 10px;
  padding-left: 20px;
}

.form-contact-us {
  display: flex;
  flex-direction: column;
}

.address-item {
  color: #313F50;
  font-size: 18px;
  font-weight: 700;
}

.contact-us-list li:not(:first-child) {
  padding-top: 60px;
}

.form-contact-us {
  padding-top: 90px;
}
.photo_container {
  margin-top: 20px;
  display: flex;
  flex-wrap: wrap;
}

.form-contact-us h3 {
  margin-top: 10px;
  color: #313F50;
  font-size: 24px;
  font-style: normal;
  font-weight: 400;
  margin-bottom: 40px;
}

.form-contact-us label {
  margin-top: 30px;
  margin-bottom: 10px;
}

.form-contact-us input {
  padding-left: 8px;
  padding-right: 8px;
  font-size: 21px;
  height: 40px;
  border: 1px solid #313F50;
  font-family: "Montserrat", sans-serif;
}

.form-contact-us textarea {
  height: 148px;
  border: 1px solid #313F50;
  resize: none;
  font-family: "Montserrat", sans-serif;
  padding-left: 8px;
  padding-right: 8px;
  padding-top: 8px;
  font-size: 18px;
}
/* Стиль для всех слайдов (бледные) */
.swiper-slide {
  opacity: 0.5; /* Устанавливаем прозрачность для всех слайдов */
  transition: opacity 0.3s ease; /* Плавное изменение прозрачности */
}

/* Стиль для активного (центрального) слайда */
.swiper-slide.swiper-slide-active {
  opacity: 1; /* Устанавливаем полную непрозрачность для активного слайда */
}


.button-contact-us {
  margin-top: 30px;
  width: 200px;
  height: 44px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  background: #D85121;
  border: none;
  color: #fff;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 40px;
  cursor: pointer;
}
.radio-buttons {
  display: flex !important;
  gap: 5px; /* Устанавливает отступы между радиокнопками */
}

.radio-label {
  margin-right: 10px; /* Устанавливает отступы между меткой и радиокнопкой */
}
.radio-label .text-input {
  margin-right: 0; /* Небольшой отступ слева от текстового поля */
  width: auto; /* Автоматическая ширина поля ввода в зависимости от содержимого */
}
.map-container {
  margin-top: 80px !important;
  margin-bottom: 100px !important;
  padding-left: 25px !important;
  padding-right: 25px !important;
}

.map-container img {
  width: 100%;
}

.social-contact-us {
  display: flex !important;
  justify-content: flex-start;
  gap: 40px;
  align-items: center !important;
}

.instagram {
  scale: 1.2;
}

.form-block-mobile {
  display: none;
}

@media screen and (min-width: 319px) and (max-width: 767px) {
  .form-block-pc {
    /*display: none;*/
  }
  .form-block {
    width: 100%;
    justify-content: center;
  }
  .price-sponsors-container {
    display: flex;
  }
  .map-container img {
    min-height: 139px;
    height: auto;
    width: 100%;
  }
  .map-container {
    margin-top: 40px !important;
    margin-bottom: 0 !important;
  }
  .form-contact-us h3 {
    font-size: 1rem;
    text-align: center;
    min-width: 16rem;
    font-weight: 500;
    margin-bottom: 0;
  }
  .contact-us-title {
    margin-bottom: 0 !important;
  }
  .contact-us-description {
    margin-top: 0 !important;
  }
  .form-contact-us label {
    font-size: 0.625rem;
  }
  .form-block-mobile {
    margin-bottom: 60px;
  }
  .form-contact-us textarea {
    height: 88px;
  }
  .form-block-mobile {
    display: block;
  }
  .title-container-contact-us {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .address-block {
    width: 100%;
  }
  .title-contact-us {
    padding-top: 6rem;
    text-align: center;
  }
  .address-block {
    padding-top: 0;
  }
  .address-item {
    font-size: 0.875rem;
    font-weight: 700;
  }
  .contact-us-list p {
    font-size: 0.75rem;
  }
  .phone-contact-us {
    display: flex;
    flex-direction: column;
    color: #313F50;
  }
  .contact-us-list li:not(:first-child) {
    padding-top: 15px;
  }
  .contact-us-list li:last-child {
    padding-top: 3rem;
    justify-content: center;
  }
  .form-contact-us {
    padding-top: 3.75rem;
  }
  .button-wrapper {
    display: flex;
    justify-content: center;
  }
  .title-packages {
    padding-top: 60px !important;
  }
}

@media screen and (min-width: 319px) and (max-width: 350px) {
  .contact-us-list div p {
    padding-left: 14px;
  }
}

.mobile-class-contact {
  padding: 14px 0 14px 0 !important;
}

@media screen and (min-width: 424px) and (max-width: 767px) {
  .address-block {
    justify-content: center;
  }
  .price-sponsors-container {
    display: flex;
  }
  .phone-contact-us {
    flex-direction: row;
  }
  .title-contact-us {
    text-align: center;
  }
  .line-contact-us {
    justify-content: center;
  }
}

@media screen and (min-width: 767px) and (max-width: 1023px) {
  .phone-contact-us {
    flex-direction: column;
    display: flex;
  }
  .price-sponsors-container {
    display: flex;
  }

  .contact-us-list div p {
    font-size: 15px;
  }

  .form-contact-us h3 {
    font-size: 16px;
  }

  .form-contact-us label {
    margin-top: 15px;
  }
  .form-contact-us {
    align-items: center;
  }
  .form-contact-us h3 {
    text-align: center;
  }
  .form-contact-us textarea {
    width: 249px;
  }
  .form-contact-us label {
    font-size: 15px;
  }
  .social-contact-us {
    display: flex;
    justify-content: center;
  }
}

/* Past Events */

.past-events-wrapper {
  display: flex;
  justify-content: space-around;
  padding-top: 80px;
  padding-bottom: 150px;
}

.event-item {
  border: 2px solid #D85121;
  text-align: center;
}

.event-btn {
  margin-bottom: 40px;
  width: 200px;
  height: 44px;
  font-size: 18px;
  font-weight: 500;
  background: #D85121;
  color: white;
  border: none;
  cursor: pointer;
}

.event-title {
  color: #313F50;
  font-size: 24px;
  font-weight: 700;
  line-height: 60px;
}

.event-item img {
  width: 100%;
}

@media screen and (min-width: 1111px) and (max-width: 1365px) {
  .event-item {
    width: 45%;
  }
}

@media screen and (min-width: 1023px) and (max-width: 1111px) {
  .event-item {
    width: 45%;
  }
}

@media screen and (min-width: 767px) and (max-width: 1023px) {
  .event-item {
    width: 45%;
  }
  .past-events-wrapper {
    padding-top: 40px;
  }
}

@media screen and (max-width: 767px) {
  .past-events-wrapper {
    flex-direction: column;
    padding-top: 20px;
    padding-bottom: 60px;
  }

  .event-title {
    font-size: 16px;
  }

  .event-item {
    margin-top: 20px;
  }
  .event-item-add {
    margin-top: 0;
  }
}

.event-contact-us {
  text-align: left;
}

.line-event-us {
  /*justify-content: left;*/
}

/* Online */

.online-item {
  width: 32.5%;
  border: 2px solid #313F50;
  margin-bottom: 20px;
  min-height: 523px;
  height: auto;
  margin-right: 0.5%;

}

.online-item img {
  width: 100%;
  height: 250px;
  object-fit: cover;
}

.online-events-wrapper {
  display: flex;
  justify-content: flex-start;
  padding-top: 80px;
  padding-bottom: 150px;
  flex-wrap: wrap;
}

.online-title {
  color: #313F50;
  font-size: 21px;
  font-style: normal;
  font-weight: 700;
  line-height: 32px;
  padding: 0 40px 0 40px;
}

.online-btn {
  margin-left: 40px;
  margin-bottom: 20px;
  width: 175px;
  height: 44px;
  font-size: 18px;
  font-weight: 500;
  background: #D85121;
  color: burlywood;
  border: none;
  margin-left: 40px;
}

.online-item {
  position: relative;
}

.online-btn {
  right: 20px;
  position: relative;
  bottom: 0;
}

@media screen and (max-width: 1023px) {
  .online-events-wrapper {
    padding-bottom: 40px;
  }
}

@media screen and (min-width: 767px) and (max-width: 1023px) {
  .online-title {
    font-size: 14px;
    padding: 0 15px 0 15px;
  }

  .online-btn {
    margin-left: 15px;
  }

  .online-item {
    min-height: 400px;
  }

  .online-events-wrapper {
    padding-top: 30px;
  }
  .swiper-container {
    padding-bottom: 0;
  }
}

@media screen and (max-width: 767px) {
  .online-item {
    width: 100%;
    min-height: 263px;
  }
  .online-item img {
    height: 120px;
    object-fit: cover;
  }
  .online-title {
    padding: 0 10px 0 10px;
    font-size: 12px;
    line-height: 12px;
  }
  .online-btn {
    margin-left: 10px;
  }
  .online-events-wrapper {
    padding-top: 20px;
  }
}

/* Photogallery */

.year {
  border-bottom: 1px solid #313F50;
  padding-top: 50px;
  padding-bottom: 20px;
  color: #313F50;
  font-size: 36px;
  font-style: normal;
  font-weight: 500;
  line-height: 60px;
}

.photogallery-events-wrapper {
  display: flex;
  flex-wrap: wrap;
  padding-bottom: 20px;
  padding-top: 20px;
  align-items: center;
  width: 100%;
}

/* Terms */

.title-terms {
  padding-top: 60px;
  color: #000;
  font-size: 24px;
  font-style: normal;
  font-weight: 500;
  line-height: 32px;
  margin: 0;
}

.text-terms {
  padding-top: 30px;
  color: #000;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 21px;
  margin: 0;
}

.last-terms {
  padding-bottom: 150px;
}

@media screen and (min-width: 319px) and (max-width: 767px) {
  .title-terms {
    padding-top: 20px;
    font-size: 16px;
  }
  .price-sponsors-container {
    display: flex;
  }

  .text-terms {
    padding-top: 10px;
    font-size: 14px;
  }

  .last-terms {
    padding-bottom: 60px;
  }

  .swiper-container {
    padding-bottom: 3rem;
  }

  .arrow-container {
    margin-top: 40px;
  }
  .title-about {
    font-size: 20px;
    line-height: 32px;
    padding-top: 105px;
  }
  .background-mobile-single {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-image: url("../images/background-mobile.png");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    z-index: -1;
  }
  .background-mobile-single::after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(9, 9, 45, 0.8);
  }
}
@media screen and (min-width: 767px) and (max-width: 1023px) {
  .background-laptop {
    background-image: url("../images/laptop-background.png");
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    z-index: -1;
  }
}

@media screen and (min-width: 767px) and (max-width: 1111px) {
  .home-arrow-container {
    margin-top: 50px !important;
    margin-bottom: 40px !important;
  }
}

@media screen and (max-width: 1111px) {
  .home-container {
    height: auto;
  }
  .home-arrow-container {
    margin-top: 76px;
    margin-bottom: 0;
  }

  .title-terms {
    padding-top: 40px;
    font-size: 18px;
  }
  .text-terms {
    padding-top: 10px;
    font-size: 14px;
  }
  .last-terms {
    padding-bottom: 100px;
  }
}

.arrow-events div {
  color: #ffffff;
}

@media screen and (min-width: 319px) and (max-width: 374px) {
  .popup-with-zoom-anim p {
    font-size: 9px;
  }
  .popup-with-zoom-anim2 p {
    font-size: 9px;
  }
  .become-delegate p {
    font-size: 9px;
  }
  .download-agenda p {
    font-size: 9px !important;
  }
}



#home-event-container {
  margin-top: 105px;
}

.slider-rc {
  position: relative;
  top: 60px;
}

#pdf-bottom {
  margin-top: 5rem;
}

.document-pdf .react-pdf__Page {
  height: 500px;
}
.document-pdf .react-pdf__Page__textContent.textLayer {
  display: none;
}
.document-pdf .react-pdf__Page__annotations.annotationLayer {
  display: none;
}
.enlarged {
  position: fixed;
  top: 20px; /* Замените на высоту вашего хедера */
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.8);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 999; /* Помогает обеспечить, что .enlarged будет выше других элементов на странице */
}

.enlarged img {
  max-width: 100%; /* Максимальная ширина увеличенного изображения */
  max-height: 100%; /* Максимальная высота увеличенного изображения */
}
.gallery-navigation {
  position: fixed;
  top: 70%;
  width: 100%;
  display: flex;
  justify-content: space-between;
  z-index: 1000; /* Убедимся, что кнопки находятся выше .enlarged */
}

.gallery-navigation button {
  background-color:#050523;
  border: 1px solid #343bc2;
  color: white;
  font-size: 44px;
  font-family: "Comic Sans MS", cursive;
  padding: 25px;
  cursor: pointer;
}
@media (max-width: 900px) {
  .gallery-navigation {
    top: 75%;
  }
  .gallery-navigation button {
    font-family: "Comic Sans MS", cursive;
    padding: 10px;
    font-size: 20px;

    /* Ваши стили для узких экранов */
  }
}

/* Стили для экранов с шириной от 601px до 900px */

.enlarged-background {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000; /* Настройте на ваш вкус, чтобы элементы находились над остальными */
}

.enlarged-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.7); /* Здесь значение 0.7 определяет прозрачность */
  z-index: 1001; /* Выше, чем фоновый элемент */
}






.add-about {
  padding-top: 44px;
  padding-bottom: 44px;

}

.speaker-style img {
  width: 203px;
  height: 178px;
  object-fit: cover;
}
.speaker-style-special img {
  width: 203px;
  height: 178px;
  object-fit: contain;
}
.second_title {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  list-style-type: disc;
}


.second_ditle_list li::marker {
  color: #313F51 !important; /* Задайте нужный цвет */
  list-style-type: disc; /* Тип маркера */
}

.logo-company {
  padding: 10px;
  width: 50%;
  height: auto;
  display: flex;
}
.custom-iframe {
  border: 3px solid #313F50; /* Цвет рамки, в данном случае, красный */
  border-radius: 10px; /* Закругление углов */
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1); /* Тень */
}

@media screen and (max-width: 767px) {
  .logo-company {
    padding-top: 5px;
  }
  .key-container-block {
    width: 100%;
  }

  .key-block img {
    margin-top: 0.6rem;
  }
  .content.active {
    display: block;
    width: 100% !important;
  }
  .partners-list li {
    width: 100%;
  }
  #content-conferences {
    width: 100%;
  }
}

.content.active {
  width: 100%;
}

.description-content {
  width: 100%;
}

.data-content {
  text-align: left;
  padding-bottom: 20px;
  padding-top: 4px;
}

.container-content {
  flex-direction: row;
}

.error-page {
  width: 100%;
  display: flex;
  justify-content: center;
}

.error-page img {
  object-fit: cover;
  width: 100%;
}

@media screen and (min-width: 1023px) {
  .error-page img {
    width: 50%;
  }
}

.error-title {
  margin-top: 0;
  margin-bottom: 0;
  padding-top: 140px;
  text-align: center;
}

.error-btn-container {
  display: flex;
  justify-content: center;
  padding-top: 20px;
  padding-bottom: 20px;
}

@media screen and (max-width: 1023px) {
  .error-title {
    font-size: 18px;
    padding-top: 95px;
  }
}

.hover-upcoming {
  padding: 33px 14px 33px 14px;
  width: 100% !important;
  height: 100%;
  min-height: 500px;
  -webkit-padding: 33px 14px 33px 14px;
  -webkit-width: 100% !important;
  -webkit-height: 100%;
  -webkit-min-height: 500px;
}

.addactive {
  width: 50% !important;
}

.sponsors-title {
  padding-top: 0;
}

.price-sponsors {
  color: #313F50;
  text-align: center;
  font-size: 30px;
  font-weight: 500;
}

.price-sponsors-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.sponsor-image {
  width: 220px;
}

@media screen and (min-width: 319px) and (max-width: 767px) {
  .price-sponsors {
    font-size: 20px;
  }
  .sponsor-image {
    margin-bottom: 20px;
    margin-top: 20px;
    width: 170px;
  }
  .key-block {
    display: flex;
    gap: 15px;
    align-items: flex-start;
    width: 100%;
  }
}

.slide-swiper {
  width: 100%;
  height: 250px;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  opacity: 0.5;
  transition: none;
  margin: 0 !important;
}
.slide-image {
  width: 100%;
  height: 300px; /* Задайте подходящую высоту */
  object-fit: cover;
  display: block;
}
.swiper-wrapper {
  align-items: center;
}

.swiper-slide-active .slide-swiper {
  width: 100%;
  height: 303px;
  opacity: 1;
  margin: 0 !important;
}

.swiper-container {
  padding-top: 50px;
}

.swiper-slide:not(.swiper-slide-active)::before {
  background: none;
}

/* Modal.css */
.modal-overlay {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background-color: #313F5080;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1042; /* Увеличено значение z-index */
  box-sizing: border-box;
  padding-left: 6px;
  padding-right: 6px;
}

.modal {
  border-radius: 0;
  position: relative;
  pointer-events: auto;
  z-index: 3;
  width: 100%;
  max-height: 100%; /* Добавлено ограничение по высоте */
  overflow-y: auto; /* Добавлено управление переполнением */
}

.close-button { /* Изменен селектор */
  position: fixed;
  top: 0;
  right: 15px;
  border: none;
  background-color: transparent;
  color: white;
  font-size: 68px;
  font-weight: 100;
  cursor: pointer;
  z-index: 1;
}

.modal-content {
  color: black;
  overflow-y: auto;
}

.rc-slider-handle:hover {
  border: 15px solid rgb(16, 137, 241);
  -webkit-border: 15px solid rgb(16, 137, 241);
}

.rc-slider-handle-dragging {
  box-shadow: none !important;
  border: 15px solid rgb(16, 137, 241);
}

.rc-slider-handle:focus-visible {
  box-shadow: none !important;
}

.b2b {
  padding-top: 80px;
}

.container-upcoming-events {
  --bs-gutter-x: 1.5rem;
  --bs-gutter-y: 0;
  width: 100%;
  padding-right: calc(var(--bs-gutter-x) * .5);
  padding-left: calc(var(--bs-gutter-x) * .5);
  margin-right: auto;
  margin-left: auto;
}

.conference-events-wrapper {
  --bs-gutter-x: 1.5rem;
  --bs-gutter-y: 0;
  display: flex;
  flex-wrap: wrap;
  margin-top: calc(-1 * var(--bs-gutter-y));
  margin-right: calc(-.5 * var(--bs-gutter-x));
  margin-left: calc(-.5 * var(--bs-gutter-x));
}

.col-class-upcoming {
  flex: 0 0 auto;
  width: 50%;
}

@media screen and (max-width: 1023px) {
  .col-class-upcoming {
    width: 100%;
    height: 100% !important;
  }
  .conference-events-wrapper {
    flex-direction: column;
    --bs-gutter-x: 0;
  }
  .container-upcoming-events {
    --bs-gutter-x: 0rem;
  }
  .action-block ul p {
    text-align: center;
    font-size: 10px;
  }
  .container-content {
    flex-direction: column;
  }
  .width-conferences {
    width: 100% !important;
  }
  .padding-list-ivents {
    padding-top: 50px !important;
  }
}

.padding-list-ivents {
  padding-top: 80px;
}

.slider-dot::after {
  content: "";
  position: absolute;
  top: 50%;
  left: 50%;
  width: 10px; /* Измените размеры в соответствии с вашими требованиями */
  height: 10px; /* Измените размеры в соответствии с вашими требованиями */
  background-color: white;
  border-radius: 50%;
  transform: translate(-50%, -50%);
}

.rc-slider rc-slider-horizontal rc-slider-with-marks {
  padding: 0 !important;
}

@media screen and (max-width: 767px) {
  .slider-rc {
    top: 30px;
  }

  #home-event-container {
    margin-top: 60px;
  }

  .b2b {
    padding-top: 0 !important;
  }
  .conference-block-wrapper {
    width: 100% !important;
  }
}

.mfp-close .close-button {
  transform: translateZ(0);
}

.employees-block {
  margin-top: 40px;
}

.title-about-single {
  padding-top: 40px !important;
}

.partners-block {
  margin-top: 40px;
  position: relative;
}

.section-gallery {
  margin-top: 40px;
  border-top: 1px solid #D85121;
}

.section-speakers {
  margin-top: 40px;
}

.about-container {
  margin-top: 0px;
}

@media screen and (min-width: 767px) and (max-width: 1111px) {
  .about-container {
    margin-top: 0px;
  }
  .section-speakers {
    margin-top: 40px;
  }
  .section-speakers {
    margin-top: 40px;
  }
  .partners-block {
    margin-top: 40px;
  }
}

.partners-list {
  padding-bottom: 0 !important;
}

@media screen and (min-width: 319px) and (max-width: 767px) {
  .about-container {
    margin-top: 0px;
  }

  .section-speakers {
    margin-top: 50px;
  }

  .section-gallery {
    margin-top: 50px;
  }

  .partners-block {
    margin-top: 40px;
  }

  .employees-block {
    margin-top: 50px;
  }
  .venue-section {
    margin-top: 50px;
  }
  .container-content {
    flex-direction: column;
  }
}

.slider-block-homepage {
  max-width: 1080px;
}

@media screen and (min-width: 1111px) and (max-width: 1365px) {
  .slider-block-homepage {
    max-width: 950px;
  }
}

@media screen and (min-width: 1023px) and (max-width: 1111px) {
  .slider-block-homepage {
    max-width: 850px;
  }
}

.rc-slider-mark {
  left: 20%;
}

.container-home-upcoming text {
  width: 100%;
  padding-left: 20px;
  padding-right: 20px;
}

.width-conferences {
  width: 65%;
}

.image-parent2 {
  width: 100%;
}


.image-parent1 {
  width: 100%;
}

.image-parent3 {
  width: 100%;
}

.image-parent4 {
  width: 100%;
}

.image-parent5 {
  width: 100%;
}

.image-parent6 {
  width: 100%;
}

.image-parent7 {
  width: 100%;
}


.image-parent4 {
  width: 100%;
  height: 179px;
  object-fit: cover;
}
.image-parent3,
.image-parent2,
.image-parent7,
.image-parent6,
.image-parent5,
.image-parent1 {
  width: 100%;
  height: 139px;
  object-fit: cover;
}
.image-parent3 {
  width: 100%;
  height: 333px;
  object-fit: cover;
}


@media screen and (max-width: 767px) {
  .width-conferences {
    width: 100%;
  }
  .conference-marcs-mobile {
    font-size: 8px;
  }
  .hover-upcoming {
    padding: 20px 5px 20px;
    -webkit-padding: 20px 5px 20px;
  }
}


@media screen and (max-width: 1439px) {
  #content-conferences {
    width: 100%;
  }
}

.marks-container {
  display: flex;
  justify-content: space-around;
}

.slider-rc {
  margin: 0 auto;
}

.rc-slider-mark-text .rc-slider-mark-text-active {
  left: 2% !important;
}

.rc-slider {
  height: 20px !important;
  padding: 0 !important;
}

.marcs-style {
  color: white;
  display: block;
  transform: translateY(30px);
  font-weight: 500;
}

.conference-marcs {
  color: #313F50;
  display: block;
  transform: translateY(30px);
  font-weight: 500;
}


.conference-content-title {
  color: #313F50;
}

.conference-content-description {
  color: #313F50;
  line-height: 21px;
  font-weight: 300;
}

.conference-block-wrapper {
  width: 60%;
}

.conference-data-content {
  color: #313F50;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.content-conferences:hover .conference-data-btn {
  background: burlywood;
  -webkit-background: white;
}

.content-conferences:hover .conference-data-link {
  color: #313F50;
  -webkit-color: #313F50;
}

.conference-marcs-mobile {
  color: #313F50;
}

.marcs-mobile-style {
  color: white;
  font-size: 8px;
  transform: translateY(10px);
  display: block;
}

@media screen and (min-width: 767px) and (max-width: 1023px) {
  .marcs-mobile-style {
    font-size: 12px;
  }
  .b2b {
    padding-top: 50px;
  }
  .conference-block-wrapper {
    width: 100%;
  }
  .conferences-cards {
    margin-bottom: 20px;
  }
  .conference-marcs-mobile {
    transform: translateY(10px);
    display: block;
  }
  .upcoming-container {
    padding-left: 30px;
    padding-right: 30px;
  }
}

.key-block {
  opacity: 1;
  transform: translateY(0);
  transition: opacity 0.5s, transform 0.5s, max-height 0.5s;
  max-height: 170px;
  overflow: hidden;
}

.hidden {
  opacity: 0;
  transform: translateY(20px);
  max-height: 0;
}

.key-point-btn {
  transition: opacity 0.3s, transform 0.5s;
  cursor: pointer;
}

.icon {
  display: inline-block;
  transition: transform 0.5s;
}

.icon-flip {
  transform: rotate(180deg);
}
#hotelSwiper {
  position: relative; /* Обеспечивает относительное позиционирование для дочерних элементов с абсолютным позиционированием */
  padding-bottom: 40px; /* Добавляет отступ снизу, чтобы было место для пагинации */
}
.swiper-pagination {
  position: absolute; /* Абсолютное позиционирование для пагинации */
  bottom: 100px; /* Расстояние от нижней границы контейнера Swiper */
  left: 0; /* Выравнивание слева */
  width: 100%; /* Ширина на весь контейнер */
  display: flex; /* Используем flexbox для выравнивания точек */
  justify-content: center; /* Центрируем точки */
  text-align: center;
}
.section-gallery .swiper {
  position: relative;
  padding-bottom: 40px; /* Пространство для пагинации */
}

/* Стили для активной точки пагинации */
.swiper-pagination-bullet-active {
  opacity: 1; /* Большая прозрачность для активной точки */
  background-color: #007BFF; /* Цвет активной точки */
}
@media  screen and (min-width: 767px) and (max-width: 1023px) {
  .block-icons-div ul p {
    font-size: 14px;
  }
}

.single-arrow-container {
  margin: 40px;
  display: flex;
  justify-content: space-between;
}

.second-bread {
  padding-right: 15px;
}

@media screen and (min-width: 319px) and (max-width: 1023px) {
  .item-1 {
    background-position-y: 25px !important;
  }
}

.add-link-breadcrumbs {
  padding-right: 15px;
}


.gallery-item {
  border: none;
  min-height: auto;
  margin-bottom: 0px !important;
}

@media screen and (max-width: 1023px) {
  .gallery-item-wrapper {
    padding-top: 100px;
  }
}

@media screen and (max-width: 767px) {
  .gallery-item img {
    height: auto;
  }
}

.opacity-gallery {
  height: 80px;
  opacity: 0;
  display: block;
}

@media screen and (min-width: 767px) and (max-width: 1023px) {
  .swiper-slide-active .slide-swiper {
    height: 200px;
  }

  .slide-swiper {
    height: 100px;
  }

  .opacity-gallery {
    height: 50px;
  }
}

@media screen and (min-width: 319px) and (max-width: 767px) {
  .swiper-slide-active .slide-swiper {
    height: 162px;
  }

  .slide-swiper {
    height: 100px;
  }

  .opacity-gallery {
    height: 20px;
  }
}

/*.unsubscribe-block {*/
/*  height: 100vh;*/
/*}*/



.unsubscribe-image {
  z-index: -2;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.centered-block {
  padding-top: 10px;
  padding-bottom: 150px;
  display: flex;
  flex-direction: column;
  text-align: center;
  align-items: center;
}
.centered-block-postmaterial {
  padding-top: 50px;
  padding-bottom: 10px;
  display: flex;
  flex-direction: column;
  gap: 20px;
  text-align: center;
  align-items: center;
  justify-content: center;
}

.delegate-list-button {
  margin-top: 10px;
  margin-bottom: 10px;
  background-color: #D85121;
  color: #fff;
  border: none;
  padding: 15px 30px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 24px;
  cursor: pointer;
  border-radius: 5px;
}

.video-buttons {
  display: flex;
  gap: 20px;
  margin-top: 20px;
  margin-bottom: 20px;
}

.first-day-video-button {
  background-color: #f16c20; /* Оранжевый цвет для первой кнопки */
  color: #fff;
  border: none;
  padding: 15px 30px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 24px;
  cursor: pointer;
  border-radius: 5px;
}

.second-day-video-button {
  background-color: #ce154c; /* Красный цвет для второй кнопки */
  color: #fff;
  border: none;
  padding: 15px 30px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 24px;
  cursor: pointer;
  border-radius: 5px;
}



.custom-link {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  justify-content: center; /* или space-between, если нужно разнести иконку и текст */
  gap: 8px; /* расстояние между иконкой и текстом */
  padding: 15px 15px;
  background-color: rgba(0, 110, 255, 0.2); /* прозрачный фон, если был */
  border-radius: 6px;
  color: white;
  text-decoration: none;
}
.custom-link:hover {
  text-decoration: none; /* Убираем подчеркивание */
  color: #D85121; /* Устанавливаем желаемый цвет текста */
  transition: all 0.3s ease-in;
}
.stripe-block {
  padding-top: 50px;
  padding-bottom: 70px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.unsubscribe-title {
  font-weight: 500;
  font-size: 48px;
  line-height: 60px;
  text-align: left;
  margin-bottom: 10px;
  margin-top: 0;
  color: white;
}

.unsubscribe-form {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.unsubscribe-form label {
  color: white;
  font-size: 21px;
  font-style: normal;
  font-weight: 400;
  padding-top: 20px;
  margin-top: -10px;
  margin-bottom: 10px;
  text-align: center;
  width: 524px;
}

.unsubscribe-email,
.unsubscribe-form textarea {
  height: 33px;
  border: 1px solid #ccc;
  outline: none;
  font-size: 26px;
  font-family: Montserrat, sans-serif;
  padding: 5px;
  width: 524px;
}

.unsubscribe-form textarea {
  resize: none;
  height: 200px;
}

.unsubscribe-form input:focus,
.unsubscribe-form textarea:focus {
  border: 1px solid #ccc;
}


.unsubscribe-btn {
  font-size: 18px;
  font-weight: 500;
  line-height: 24px;
  background: #313F50;
  color: white;
  width: 201px;
  height: 44px;
  border: none;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
}

.unsubscribe-breadcrumbs {
  color: white;
}

/*.radio-label {*/
/*  display: flex;*/
/*  align-items: center;*/
/*}*/
.radio-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
.radio-input {
  margin-right: 0px; /* Обычно достаточно небольшого отступа между кнопкой и меткой */
}

.form-label {
  text-align: center;
  margin-bottom: 5px; /* Добавьте отступ снизу между лейблами */
}


.form-label-group {
  display: flex;
  flex-direction: column;
}
@media screen and (min-width: 319px) and (max-width: 767px) {
  .unsubscribe-title {
    font-size: 24px;
    line-height: 32px;
  }

  .unsubscribe-form label {
    font-size: 16px;
  }

  .unsubscribe-email, .unsubscribe-form textarea {
    width: 250px;
  }

  .unsubscribe-form label {
    width: 300px;
  }

  .modal-close-button img {
    width: 30px;
    height: 30px;
  }

  .mfp-close {
    transform: scale(0.7);
  }

  .scroll-container {
    max-height: 450px;
    overflow-x: hidden;
  }
}

@media screen and (max-width: 1023px) {
  .new-line-conference {
    display: none;
  }
  .stripe-block {
    padding-bottom: 10px;
  }
}

@media screen and (min-width: 767px) and (max-width: 1023px) {
  .unsubscribe-title {
    font-size: 32px;
    padding-top: 10px;
  }
  .stripe-block {
    padding-top: 10px;
  }
}

.swiper, swiper-container {
  z-index: 0 !important;
}

.modal-container {
  text-align: left;
}


.conference-data-btn {
  background-color: #313F50;
}

.line-conference-new {
  background-color: #313F50;
}

.container-arrows-pdf {
  position: absolute;
}

.left-arrow-black {
  background-image: url("../images/arrow-black-left.png");
  margin-right: 10px;
  height: 10px;
  background-repeat: no-repeat;
  width: 100%;
  background-size: contain;
  background-position: right;
  cursor: pointer;
}

.right-arrow-black {
  background-image: url("../images/arrow-black-right.png");
  margin-left: 10px;
  height: 10px;
  background-repeat: no-repeat;
  width: 100%;
  background-size: contain;
  background-position: left;
  cursor: pointer;
}

.container-black-arrows {
  display: flex;
  justify-content: space-between;
}

.arrows-wrapper-black {
  padding-top: 20px;
}

@media screen and (max-width: 767px) {
  .sponsors-list {
    min-height: 100%;
  }
  sponsors-list-btel{
    min-height: 100%;
  }
}

@media screen and (min-width: 767px) and (max-width: 1023px) {
  .sponsors-list {
    min-height: 200px;
  }
  .sponsors-list-btel {
    min-height: 200px;
  }
}


@media screen and (min-width: 1023px) and (max-width: 1111px) {
  .sponsors-list {
    min-height: 250px;
  }
  .sponsors-list-btel {
    min-height: 250px;
  }
}

@media screen and (min-width: 1111px)  {
  .sponsors-list {
    padding-bottom: 0;
  }
  .sponsors-list-btel {
    padding-bottom: 0;
  }
}

@media screen and (min-width: 767px) {
  .sponsors-list li {
    text-align: left;
  }
}

/*.title-content {*/
/*  height: 120px !important;*/
/*}*/

/*.description-content {*/
/*  height: 300px;*/
/*}*/


@media screen and (max-width: 360px) {
  .container-pdf {
    padding-left: 5px;
  }
}

@media screen and (max-width: 1023px) {
  .title-content {
    height: 100%;
  }
  .description-content {
    height: 100%;
  }
}

.container-stripe {
  background: #313F50;
}

.contact-list-stripe li h2{
  color: white;
}

.contact-list-stripe li h4 {
  color: white;
}

.contact-list-stripe li h3 {
  color: white;
}

.contact-list-stripe li p {
  color: white;
}

.title-contact-us-stripe {
  padding-top: 0;
  font-size: 36px;
  color: white;
}

.contact-list-stripe {
  padding-top: 20px;
  padding-bottom: 50px;
  margin-bottom: 0px;
}

.stripe-container {
  background: #313F50;
}
.date-container {
  position: absolute;
  bottom: 10px;
  left: 20px;
  /* background-color: #D85121; */
  border-radius: 50%;
  /* width: 100px; */
  /* height: 64px; */
  display: flex;
  justify-content: center;
  align-items: flex-start;
  color: #000;
  font-size: x-large;
  font-weight: bold;
}
.capitalize {
  text-transform: capitalize;
}

.date-text {
  margin-right: 20px;
  position: relative;
  color: #000;
  font-size: 14px;
  font-weight: bold;
}
.paypal-wrapper iframe {
  display: flex;
  align-items: flex-end;
}

.paypal-width  {
  min-width: 130px !important;
}

@media screen and (max-width: 500px) {
  .paypal-wrapper iframe {
    max-width: 75px !important;
  }
  .paypal-width  {
    min-width: 75px !important;
    max-width: 75px;
  }
}

.btn-content:hover a {
  color: #313F50;
  -webkit-color: #313F50;
}


@media screen and (min-width: 1111px) and (max-width: 1443px) {
  .title-content {
    font-size: 21px;
  }
  .price-sponsors {
    font-size: 30px;
  }
}

.sponsors-wrapper {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  flex-direction: column;
  align-content: center;
}
.sponsors-image {
  display: flex;
  justify-content: center;
  align-items: center;
}



@media screen and (min-width: 549px) {
  .custom-sponsors {
    width: 50%;
  }
  .price-sponsors-container {
    display: inline;
    text-align: center;
  }
  .sponsor-image {
    margin-top: 20px;
    margin-bottom: 10px;

  }
}

.notification-success {
  background-color: #04044C !important;
  color: white;
}




@media screen and (min-width: 319px) and (max-width: 767px) {
  .title {
    padding-top: 50px;
  }
}

.notification {
  margin-bottom: 15px;
}


.number-timer {
  font-size: 48px;
  font-weight: bold;
  color: rgba(255, 255, 255, 0.6); /* Прозрачный белый цвет текста */
  /*background-color: #313F50; !* Синий фон *!*/
  border-radius: 20px; /* Закругленные углы */
  /*display: inline-block; !* Сделать блочный элемент *!*/
  width: 100px; /* Ширина элемента */
  height: 60px; /* Высота элемента */
  text-align: center; /* Центрирование текста */
  line-height: 60px; /* Выравнивание текста по центру */
  margin-right: 10px; /* Расстояние между цифрами */
  box-shadow: 0 0 10px white; /* Подсветка белого цвета */
  /*min-width: 50px;*/
}

.days-timer {
  /*color: burlywood;*/
  font-weight: 500;
  padding-right: 10px;
  font-size: 18px;
  padding-top: 10px;
  min-width: 50px;
}

.timer-wrapper {
  display: flex;
  padding-bottom: 44px;
  justify-content: center;
  padding-top: 10px;
}

.timer-title {
  /*margin-left: 180px;*/
  text-align: center;
  margin-bottom: 17px;
  color: rgba(255, 255, 255, 0.6);
  font-size: 18px;
  font-weight: 900;
  text-transform: uppercase;
}


@media screen and (max-width: 767px) {
  .number-timer {
    border-radius: 0;
    width: 20px;
    box-shadow: 0 0 0;
    min-width: 0;
    /*min-width: 10px;*/
    font-size: 21px;
  }

  .days-timer {
    color: burlywood;
    min-width: 10px;
    font-size: 12px;
    padding-top: 0;
  }

  .timer-wrapper {
    align-items: center;
  }

  .timer-title {
    font-size: 15px;
    margin-bottom: 0;
  }
  .sponsors-image {
    display: flex;
    flex-direction: column;
  }

}

@media screen and (min-width: 1023px) {
  .sponsor-image {
    padding: 25px;
  }

}

.employee-mailto {
  color: black;
}

.employee-mailto:hover {
  text-decoration: none;
  color: #D85121;
  transition: all 0.3s ease-in;
  cursor: pointer;
  -webkit-text-decoration: none;
  -webkit-color: #D85121;
  -webkit-transition: all 0.3s ease-in;
  -webkit-cursor: pointer;
}
@media screen and (max-width: 767px) {
  .galochka {
    font-size: 10px;}
}


@media screen and (min-width: 767px) and (max-width: 1023px) {
  .online-item img {
    height: 150px;
  }
  .online-title {
    line-height: 24px;
  }
  .online-item {
    width: 32%;
  }
  .date-container {
    display: none;
  }
  .hover-upcoming {
    min-height: 100%;
    -webkit-min-height: 100%;
  }

}

@media screen and (min-width: 1023px) and (max-width: 1365px) {
  .online-title {
    line-height: 26px;
    font-size: 18px;
  }
  .online-item {
    width: 32.4%;
  }
}

@media screen and (min-width: 1023px) and (max-width: 1100px) {
  .online-btn {
    margin-left: 6px;
  }
}

@media screen and (max-width: 767px){
  .online-btn {
    width: 150px;
  }
}
.buttons{
  display: flex;
  flex-direction: column;
  justify-content: center;

}

.post_button_presentation{
  margin-top: 5px;
  background-color: #007BFF;
  color: #fff;
  border: none;
  padding: 10px 20px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  cursor: pointer;
  border-radius: 5px;
}
@media screen and (min-width: 319px) and (max-width: 767px) {
  .post_button_presentation{
    padding: 5px 3px;
    font-size: 14px;
  }

}
.conference-location {
  padding-top: 5px;
}

.left-arrow-partners, .right-arrow-partners {
  position: absolute;
  top: 54%;
  margin-right: 10px;
  margin-left: 10px;
  z-index: 10;
  width: 120px;
  color: black;
  font-size: 60px;
  cursor: pointer;
  box-sizing: border-box;
  user-select: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
}

.left-arrow-partners {
  left: 1px;
}


.carousel-container {
  position: relative;
  overflow: hidden;
}

.carousel-container .swiper-wrapper {
  display: flex;
  transition: transform 0.3s ease-in-out;
}

.carousel-container .swiper-slide {
  flex: 0 0 auto;
}



.your-existing-li-class {
  margin-top: 50px;
  width: 100%;
  opacity: 0.5; /* Устанавливаем прозрачность для всех слайдов */
  transition: opacity 0.3s ease; /* Плавное изменение прозрачности */
}


@media screen and (max-width: 767px) {
  .left-arrow-partners, .right-arrow-partners {
    top: 55%;
    margin-right: 160px;
    margin-left: 160px;
  }
  .swiper-slide.swiper-slide-active{
    opacity: 1;
  }
}
@media screen and (min-width: 320px) and (max-width: 767px) {
  .venue-description {
    font-size: 10px;
  }
  .how-to-get-button {
    font-size: 10px;
  }
  .address-line {
    width: 55%;
    margin-right: 5px;
  }

  .left-arrow-partners, .right-arrow-partners {
    top: 55%;
    width: 65px;
    margin-right: 10px;
    margin-left: 10px;
  }

}

@media screen and (min-width: 767px) and (max-width: 1023px) {
  .left-arrow-partners {
    position: absolute;
    top: 55%;
    z-index: 10;
    margin-right: 25px;
    margin-left: 3px;
    width: 63px;
    color: black;
    font-size: 60px;
    cursor: pointer;
    box-sizing: border-box;
    user-select: none;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
  }
  .right-arrow-partners {
    position: absolute;
    top: 55%;
    z-index: 10;
    margin-left: 10px;
    margin-right: 0;
    width: 63px;
    color: black;
    font-size: 60px;
    cursor: pointer;
    box-sizing: border-box;
    user-select: none;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
  }
}
@media screen and (min-width: 1024px) and (max-width: 1300px) {
  .left-arrow-partners{
    margin-left: 5px;
    width: 65px;
  }
  .right-arrow-partners{
    margin-right: 5px;
    width: 65px;
  }
}
@media screen and (min-width: 1301px) and (max-width: 1440px) {
  .left-arrow-partners{
    margin-left: 10px;
    width: 100px;
  }
  .right-arrow-partners{
    margin-right: 10px;
    width: 100px;
  }
}
@media (min-width: 769px) {
  .mobile-menu {
    display: none;
  }
}

/* Mobile styles */
@media (max-width: 768px) {
  .desktop-header {
    display: none;
  }

  .mobile-header {
    display: flex;
    padding: 2px 25px;
    transition: background-color 0.3s ease;
    background-color: initial;
  }

  .mobile-header-scrolled {
    background-color: #313F50;
  }

  .mobile-class {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  /* NEW HAMBURGER STYLES - Using spans instead of SVG */
  .hamburger-container {
    position: relative;
    z-index: 10000;
  }

  .hamburger-button {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 30px;
    height: 20px;
    background: transparent;
    border: none;
    cursor: pointer;
    padding: 0;
    z-index: 10;
  }

  .bar {
    width: 100%;
    height: 3px;
    background-color: white;
    border-radius: 3px;
    transition: all 0.3s ease-in-out;
    display: block;
  }

  /* X transformation styles */
  .hamburger-button.active .bar1 {
    transform: translateY(8.5px) rotate(45deg);
  }

  .hamburger-button.active .bar2 {
    opacity: 0;
  }

  .hamburger-button.active .bar3 {
    transform: translateY(-8.5px) rotate(-45deg);
  }

  /* Mobile nav menu */
  .mobile-nav {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: #313F50;
    z-index: 999;
    transform: translateX(-100%);
    transition: transform 0.3s ease-in-out;
    padding: 60px 20px 20px;
    overflow-y: auto;
  }

  .mobile-nav.open {
    transform: translateX(0);
  }

  .list-navigation {
    display: flex;
    flex-direction: column;
    gap: 15px;
    list-style: none;
    padding: 0;
    margin: 0;
  }

  .mobile-list {
    color: white;
    font-size: 16px;
    padding: 3px;
    border-bottom: 1px solid rgba(255, 255, 255, 0.1);
  }

  .line-burger {
    display: block;
    height: 1px;
    background-color: rgba(255, 255, 255, 0.2);
    margin: 20px 0;
  }

  .social-mobile {
    display: flex;
    gap: 15px;
    margin-top: 20px;
  }

  .social-mobile img {
    width: 24px;
    height: 24px;
  }
}

.load-more-container {
  display: flex;
  justify-content: center;
}
.want-more-button {
  padding: 20px 40px;
  background-color: #D85121;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-weight: bold;
}

/* Для экранов шире 1200px делаем кнопку крупнее */
@media screen and (min-width: 1200px) {
  .want-more-button {
    padding: 25px 50px; /* увеличиваем внутренние отступы */
    font-size: 1.2em;  /* увеличиваем размер шрифта, если необходимо */
  }
}
.pricing-plans {
  max-width: 1200px;
  margin: 0 auto;
  padding: 20px;
}

.pricing-title {
  font-size: 28px;
  font-weight: bold;
  color: #1a3b5d;
  margin-bottom: 30px;
}

.pricing-cards {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  margin-bottom: 40px;
}

.pricing-card {
  flex: 1 1 22%;
  min-width: 250px;
  background-color: #f9f9f9;
  border-radius: 8px;
  padding: 20px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.card-header {
  display: flex;
  align-items: center;
  gap: 10px;
  margin-bottom: 15px;
}

.card-header h3 {
  font-size: 18px;
  font-weight: bold;
  margin: 0;
}

.pricing-features {
  list-style: none;
  padding: 0;
  margin: 0 0 20px 0;
}

.pricing-features li {
  display: flex;
  align-items: flex-start;
  margin-bottom: 8px;
  font-size: 14px;
}

.bullet {
  display: inline-block;
  width: 8px;
  height: 8px;
  background-color: #ff6b00;
  border-radius: 50%;
  margin-right: 8px;
  margin-top: 6px;
  flex-shrink: 0;
}

.more-details-btn {
  background-color: #ff6b00;
  color: white;
  border: none;
  border-radius: 4px;
  padding: 8px 16px;
  font-weight: bold;
  cursor: pointer;
  transition: background-color 0.3s;
}

.more-details-btn:hover {
  background-color: #e06000;
}

/* Стили для секции с формой */
.request-section {
  max-width: 1200px;
  margin: 40px auto;
  padding: 20px;
}

.request-container {
  display: flex;
  background-color: #fff;
  border-radius: 8px;
  overflow: hidden;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
}

.request-image {
  flex: 1;
  position: relative;
  min-height: 300px;
  background-color: #1a3b5d;
}

.request-image img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.request-image-label {
  position: absolute;
  top: 20px;
  left: 20px;
  background-color: rgba(0, 0, 0, 0.7);
  color: white;
  padding: 5px 10px;
  border-radius: 4px;
  font-weight: bold;
}

.fire-icon {
  margin-right: 5px;
}

.request-form {
  flex: 1;
  padding: 30px;
}

.request-title {
  font-size: 22px;
  margin-bottom: 20px;
  color: #333;
  line-height: 1.3;
}

.request-price {
  color: #ff6b00;
}

.payment-methods {
  gap: 20px;
  margin-bottom: 20px;
}

.contact-form input {
  width: 100%;
  padding: 12px;
  margin-bottom: 15px;
  border: 1px solid #ddd;
  border-radius: 4px;
}

.submit-btn {
  width: 100%;
  background-color: #ff6b00;
  color: white;
  border: none;
  padding: 12px;
  font-weight: bold;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.submit-btn:hover {
  background-color: #e06000;
}

.privacy-policy {
  font-size: 12px;
  color: #777;
  margin-top: 10px;
  text-align: center;
}